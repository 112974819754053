import * as styles from '../css/.module/thickness_graph.css';


import * as Constants from './constants.js';
import * as Utils from './utils.js';


import Chart from 'chart.js/auto';

import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);

import { getRelativePosition } from 'chart.js/helpers';

import $ from 'jquery';

export default class ThicknessGraph {
  #target;
  #guid;
  #vessel;
  #data;
  #aggregateData;
  #axisData;
  #drillingMeasurementsStr;
  #footer;
  #canvas;
  #chart;


  constructor(target, vessel, data) {
    this.#target = target;
    this.#vessel = vessel;
    this.#data = data;
    this.#guid = Utils.guid();
    target.innerHTML =
      `<canvas id="thicknessGraphCanvas${this.#guid}" data-url=""></canvas> `;
    this.#canvas = document.getElementById(`thicknessGraphCanvas${this.#guid}`);



    this.setUpData();



  }

  setUpEventListeners() {
    this.#vessel.addEventListener(Constants.LES_SELECTED_KILN_LOCATION_CHANGE, (event) => this.onSelectedKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_HOVERED_KILN_LOCATION_CHANGE, (event) => this.onHoveredKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_ACCEPTABLE_THICKNESS_CHANGE, (event) => this.onAcceptableThicknessChange(event));
  }



  setUpData() {


    //data can be provided as an objet, a function or a url
    if (typeof this.#data === 'function') {
      const dataContainer = this.#data();
      this.#aggregateData = dataContainer.aggregates;
      this.#axisData = dataContainer.axis;
      this.#drillingMeasurementsStr = dataContainer.drillingMeasurements;
      this.setupChart();
    }
    else if (typeof this.#data === 'object') {
      this.#aggregateData = this.#data.aggregates;
      this.#axisData = this.#data.axis;
      this.#drillingMeasurementsStr = this.#data.drillingMeasurements;
      this.setupChart();
    }
    else if (typeof this.#data === 'string') {
      this.requestData();

    }
  }


  requestData() {

    $(function () {
      $.ajax({
        url: this.#data,
        success: function (data) {
          this.#aggregateData = data;
          $(function () {
            $.ajax({
              url: '/api/axisdf/?format=json&extracted_data={{object.id}}',
              success: function (data) {
                this.#axisData = data;
                this.setupChart();



              }
            });
          });

        }
      });
    });
  }



  setupChart() {
    let aggregates = this.#aggregateData;

    //Original lining Thickness   - green
    let originalThickness = this.#vessel.getOriginalBrickThickness();
    // Acceptable lining thickness - red
    let acceptableThickness = this.#vessel.acceptableThicknessSet.reduce((acc, current) => { return acc.concat([{ 'x': current.start, 'y': current.attributes.Thickness / 10 }, { 'x': current.end, 'y': current.attributes.Thickness / 10 }]); }, []);

    //lining thickness Q05 - blue
    let r_q05 = aggregates.map(val => ({ 'y': (this.#vessel.getKilnRadiusAtPosition(val.x_mean) - val.radius_q95) * 100, 'x': val.x_mean }));

    //lining thickness median - grey

    let r_median = aggregates.map(val => ({ 'y': (this.#vessel.getKilnRadiusAtPosition(val.x_mean) - val.radius_median) * 100, 'x': val.x_mean }));


    let chartAnnotations = [{
      drawTime: 'afterDatasetsDraw',
      id: 'chartSelectedPosition',
      type: 'line',
      mode: 'vertical',
      scaleID: 'x',
      value: this.#vessel.selectedPosition.x,
      borderColor: 'black',
      borderWidth: 2,
      label: {
        backgroundColor: '#888',
        content: '',
        enabled: true
      },
    }];

    for (let brickType of this.#vessel.brickTypes) {
      chartAnnotations.push({
        drawTime: 'beforeDatasetsDraw',
        type: 'box',
        xScaleID: 'x',
        xMin: brickType.start,
        xMax: brickType.end,
        backgroundColor: brickType.color,
        borderColor: brickType.color,
        borderWidth: 1,
      });


    }

    let drillingMeasurements = {};

    if (this.#drillingMeasurementsStr) {
      let drillingMeasurementsRaw = this.#drillingMeasurementsStr.split('\n'); /*1st separator*/
      let i = drillingMeasurementsRaw.length;
      while (i--) {
        if (drillingMeasurementsRaw[i] !== '')
          drillingMeasurementsRaw[i] = drillingMeasurementsRaw[i].split(';'); /*2nd separator*/
        else
          drillingMeasurementsRaw.splice(i, 1);
      }

      for (let row of drillingMeasurementsRaw) {
        let values = { x: parseFloat(row[0]), y: parseFloat(row[1]) };
        let angle = row[2].trim();
        if (drillingMeasurements[angle] == undefined)
          drillingMeasurements[angle] = [values];
        else
          drillingMeasurements[angle].push(values);

      }
    }
    let chart_datasets = [{
      label: 'Thickness Q05',
      lineTension: 0,
      data: r_q05,
      borderColor: '#003262',
      backgroundColor: '#003262',
      type: 'scatter',
      radius: 2,
    },
    {
      label: 'Thickness median',
      lineTension: 0,
      data: r_median,
      borderColor: '#9EAAB3',
      backgroundColor: '#9EAAB3',
      type: 'scatter',
      radius: 2,
    },
    {
      label: 'Acceptable Lining thickness',
      data: acceptableThickness,
      borderColor: '#ED2C2A',
      backgroundColor: '#ED2C2A',
      type: 'line',
      lineTension: 0,
    },
    {
      label: 'Original lining thickness',
      data: originalThickness,
      borderColor: '#007D00',
      backgroundColor: '#007D00',
      type: 'line',
      lineTension: 0,
    }];

    let ringSizeIncrementation = 0;
    for (let angle in drillingMeasurements) {

      chart_datasets.push({
        label: 'Drilling measurement: ' + angle,
        data: drillingMeasurements[angle],
        type: 'scatter',
        borderColor: Utils.selectColor(ringSizeIncrementation),
        backgroundColor: 'transparent',
        radius: 4 + ringSizeIncrementation, //so we are able to se overlapping
      });
      ringSizeIncrementation++;


    }




    let $chart = this.#canvas;
    let ctx = $chart.getContext('2d');
    this.#chart = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: chart_datasets
      },
      options: {
        events: ['click'],
        onClick: (e) => {
          const canvasPosition = getRelativePosition(e, this.#chart);

          // Substitute the appropriate scale IDs
          const dataX = this.#chart.scales.x.getValueForPixel(canvasPosition.x);
          const dataY = this.#chart.scales.y.getValueForPixel(canvasPosition.y);

          let location = new Utils.Location(dataX);
          this.#vessel.setSelectedPosition(location);
        },

        responsive: true,
        bezierCurve: false,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Thickness overview'
        },
        scales: {
          x: {
            display: true,
            min: 0,
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              max: this.#vessel.axisMax,
              min: 0,
            },
          },
          y: {
            display: true,
            ticks: {
              stepSize: 10,
              max: 35,
              min: 0,
            },
          }
        },
        plugins: {
          autocolors: false,
          annotation: {
            annotations: chartAnnotations,
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem, data) {
                return Number(tooltipItem.raw.y).toFixed(2);
              }
            }
          },
        }/*,
            plugins: {zoom: {
							pan: {
								enabled: true,
								mode: 'xy' 
							},
							zoom: {
								enabled: true,
								mode: 'x',
								sensitivity: 3
							}
						}
          }
          */
      }
    });

    /* now we can start listening for events */
    this.setUpEventListeners();


  }


  onHoveredKilnPositionChange() {
    //TODO: this should be result in a change here as well?

  }

  onAcceptableThicknessChange() {
    //TODO: this should be result in a change here as well?

  }


  onSelectedKilnPositionChange(event) {
    if (this.#chart) {
      let selectedPosition = event.detail.new_location.x;
      this.#chart.options.plugins.annotation.annotations[0].value = selectedPosition;
      this.#chart.options.plugins.annotation.annotations[0].label.content = selectedPosition.toFixed(2) + ' m';
      this.#chart.update();

    }

  }

}
