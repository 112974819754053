// extracted by mini-css-extract-plugin
export var brickInfoIndicator = "qxSbgzdXK_nycHZTxgOJ";
export var brickRegionInfo = "YkFP_PbbQMBYBV6nWTTY";
export var brickRegionInfoContainer = "YKcdHPAIekA1p0LhX4gm";
export var brickSection = "ao9dxQ213HMUkjEoC1mn";
export var hoverBrickInfoElem = "iJJdF4WnZksHMg7fR_F4";
export var mantleSection = "LoskunYbOCmsusFWyz11";
export var show = "Ml3beseLhRXfY2F37uGp";
export var svgBrickRegion = "LAeUaSPZWofPYHaIUSfB";
export var timeIndicator = "NiLAIepOW2yfDjz0ify7";
export var timeIndicatorText = "qikbHs0DT617nFISAksG";