

import * as styles from '../css/.module/vertical_slice.css';

import * as Utils from './utils.js';
import * as Constants from './constants.js';


import $ from 'jquery';
import * as d3 from 'd3';
import * as chroma from 'chroma-js';


export default class VerticalSlice {

  #target;
  #guid;
  #vessel;
  #data;
  #svg;
  #targetPt;

  #lowerCap;
  #upperCap;
  #showThicknessColorization;
  #spinner;
  #padding;
  #sliceMin = 0;
  #sliceMax = 0;
  
  #maxWidth;
  #maxHeight;
  #sliceThickness;
  #canvasHeight;
  #canvasWidth;
  #currentPosition = -100;
  #footer;

  #colorScale = chroma.scale('viridis');
  #verticalDistComparisonTolerance = 0.06;


  #scatter = null;
  #comparisonScatter = null;
  #sliceHeightIndicator;
  #sliceHeightIndicatorText;

  #positionIsFixed;
  #fixedPosition;
  #widthIndicator;

  constructor(target, vessel, data, { lowerCap = 0, upperCap = 220, showThicknessColorization = false,
    sliceThickness = 0.1, positionIsFixed = false, fixedPosition = null, padding = 0.2, footer = null, widthIndicator= {sameSide: true, oppositeSide: true, wallToWall: true, useComparison:false}}) {
    this.#target = target;
    this.#vessel = vessel;


    this.#lowerCap = lowerCap;
    this.#upperCap = upperCap;
    this.#showThicknessColorization = showThicknessColorization;


    this.#data = data;
    this.#guid = Utils.guid();
    this.#positionIsFixed = positionIsFixed;
    this.#fixedPosition = fixedPosition;
    this.#sliceThickness = sliceThickness;
    this.#padding = padding;
    this.#footer = footer;
    this.#widthIndicator = widthIndicator;

    this.#target.innerHTML = `
    <div class="${styles.sliceSpinner}" id="spinner${this.#guid}">
      <div class="spinner-border text-primary" style="width: 5vw; height: 5vw; margin-top: 7vw;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <svg  class="${styles.sliceContainer}" id="verticalsliceSVG${this.#guid}" width="100%" height="100%" viewBox="-2.2, -2.2, 4.4, 4.4">
    <g id="sliceDot${this.#guid}"></g>
    <g id="comparisonSliceDot${this.#guid}"></g>
    <line class="${styles.sliceHeightIndicator}" id="sliceHeightIndicator${this.#guid}" x1="0" y1="0" x2="0" y2="0" stroke="black" stroke-width=0.007>
    </line>
    <text  class="${styles.sliceHeightIndicatorText}" id="sliceHeightIndicatorTextSame" x="-10" y="-10" font-size=0.08 color = "888" ></text >
    <line class="${styles.sliceHeightIndicator}" id="sliceHeightIndicatorSame" x1="0" y1="0" x2="0" y2="0" stroke="black" stroke-width=0.007>
    </line>
    <text  class="${styles.sliceHeightIndicatorText}" id="sliceHeightIndicatorTextOpposite" x="-10" y="-10" font-size=0.08 color = "888" ></text >
    <line class="${styles.sliceHeightIndicator}" id="sliceHeightIndicatorOpposite" x1="0" y1="0" x2="0" y2="0" stroke="black" stroke-width=0.007>
    </line>
    <text  class="${styles.sliceHeightIndicatorText}" id="sliceHeightIndicatorTextWallToWall" x="-10" y="-10" font-size=0.08 color = "888" ></text >
    </svg >
  `;


    this.#svg = document.getElementById(`verticalsliceSVG${this.#guid}`);
    this.#spinner = document.getElementById('spinner' + this.#guid);
    this.#sliceHeightIndicator = document.getElementById('sliceHeightIndicator' + this.#guid);
    this.#sliceHeightIndicatorText = document.getElementById('sliceHeightIndicatorText' + this.#guid);



    // Create an SVGPoint for future math
    this.#targetPt = this.#svg.createSVGPoint();

    const rect = this.#target.getBoundingClientRect();
    this.#canvasHeight = rect.height;
    this.#canvasWidth = rect.width;




    this.#scatter = new LES.Scatter(this.#data);
    this.#scatter.fetchHeader((event) => this.headerFetched(event));

  }

  headerFetched() {

    this.#maxWidth = this.#scatter.dim1Max - this.#scatter.dim1Min;
    this.#maxHeight = this.#scatter.dim0Max - this.#scatter.dim0Min;


    const paddedXMax = (this.#scatter.dim1Max + this.#padding);
    const paddedYMin = (this.#scatter.dim0Min - this.#padding);

    this.paddedWidth = (this.#scatter.dim1Max - this.#scatter.dim1Min + this.#padding * 2);
    this.paddedHeight = (this.#scatter.dim0Max - this.#scatter.dim0Min + this.#padding * 2);

    //negation of x_min due to us wantning to flip it as y iz in "negative direction"
    this.#svg.setAttribute('viewBox', `${-paddedXMax}, ${paddedYMin}, ${this.paddedWidth}, ${this.paddedHeight}`);

    this.setupEventListeners();

    this.updatePosition();

  }


  onClick() {
    //not decided what todo... highlight somehow?

  }


  onAcceptableThicknessChange() {
    this.updateAcceptableThickness();

  }


  onHoveredKilnPositionChange(event) {
    if (event.detail.new_location != null) {

      if (Math.abs(this.#sliceMin <= event.detail.new_location.x && this.#sliceMax >= event.detail.new_location.x)) {
        // nothing done for vertical slices yet

      }

    }
  }

  updatePosition(){
    if (!this.positionIsFixed) {
      this.#sliceMin = this.#vessel.selectedPosition.x;
      this.#sliceMax = this.#vessel.selectedPosition.x+ this.#sliceThickness;

      if (!this.#comparisonScatter) {
        // we only have one scatter to worry about
        this.#scatter.getLocation(this.#sliceMin, this.#sliceMax).then(() => {this.renderSlice();});
      }
      else {
        // neeed to fetch data for both datasets
        Promise.all([
          this.#scatter.getLocation(this.#sliceMin, this.#sliceMax),
          this.#comparisonScatter.getLocation(this.#sliceMin, this.#sliceMax)
        ]).then(data => this.renderSlice());

      }
    }

  }

  onSelectedKilnPositionChange(event) {

    this.updatePosition();

  }

  setupEventListeners() {
    window.addEventListener('resize', (event) => this.onResize(event));
    this.#vessel.addEventListener(Constants.LES_HOVERED_KILN_LOCATION_CHANGE, (event) => this.onHoveredKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_SELECTED_KILN_LOCATION_CHANGE, (event) => this.onSelectedKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_ACCEPTABLE_THICKNESS_CHANGE, (event) => this.onAcceptableThicknessChange(event));


    this.#svg.addEventListener('click', (event) => this.onClick(event));
    this.#svg.addEventListener('mousemove', (event) => this.onMouseMove(event));
    this.#svg.addEventListener('mouseout', (event) => this.onMouseOut(event));
  }

  getDistScale (comparison, base, invertedDistance = false){


    //these are both sorted so for quicker access
    let prevBaseFound = 0;
    let multiplier = 1; 
    if (invertedDistance) multiplier = -1;

    const scale = new Array(comparison.length);

    for (let i = 0; i <comparison.length; i ++) {
      scale[i] = -1000000;

      while(prevBaseFound < (base.length-1) && base[prevBaseFound][1] < comparison[i][1]) {
        prevBaseFound ++;
      }


      const dist = (multiplier)*(comparison[i][2] - base[prevBaseFound][2]);
      const verticalDist = comparison[i][1] - base[prevBaseFound][1];

      if (verticalDist < this.#verticalDistComparisonTolerance) {
        scale[i] = LES.Utils.clampAndNormalize(dist*1000, this.#lowerCap, this.#upperCap);

      }



    }


    return scale;


  }

  renderSlice() {

    $(this.#spinner).fadeOut(1000);

    if (this.#scatter.pointData && this.#scatter.pointData.length > 0) {

      //split the data set int o a left and a right side
      this.pointsSortedLeft = this.#scatter.pointData.filter(point => point[2] > 0).sort(function(a, b){return a[1] - b[1];});
      this.pointsSortedRight = this.#scatter.pointData.filter(point => point[2] < 0).sort(function(a, b){return a[1] - b[1];});
      this.comparisonPointsSortedLeft = [];
      this.comparisonPointsSortedRight = [];


      //Draw or update the the individual measurements dots
      const dot = d3.select('#sliceDot' + this.#guid)
        .selectAll('circle')
        .data(this.#scatter.pointData)
        .join('circle')
        .attr('cx', dta => -dta[2]) //negative value as expected to look along the kiln and then y needs to be negated
        .attr('cy', dta => this.#scatter.dim0Min - dta[1]) //negative value as svg is bottom up;
        .attr('r', 0.01)
        .style('fill', '#424B87');


      if (this.#comparisonScatter) {

        this.comparisonPointsSortedLeft = this.#comparisonScatter.pointData.filter(point => point[2] > 0).sort(function(a, b){return a[1] - b[1];});
        this.comparisonPointsSortedRight = this.#comparisonScatter.pointData.filter(point => point[2] < 0).sort(function(a, b){return a[1] - b[1];});
   
  

        if (this.#showThicknessColorization) {
          const comparisonSortedLeft = this.#comparisonScatter.pointData.filter(point => point[2] > 0).sort(function(a, b){return a[1] - b[1];});
          const baseSortedLeft = this.pointsSortedLeft; 
          const comparisonSortedRight = this.#comparisonScatter.pointData.filter(point => point[2] < 0).sort(function(a, b){return a[1] - b[1];});
          const baseSortedRight = this.pointsSortedRight;
          let distScale = this.getDistScale(comparisonSortedLeft, baseSortedLeft);
          distScale = distScale.concat(this.getDistScale(comparisonSortedRight, baseSortedRight, true));
          //Draw or update the the individual measurements dots


          d3.select('#comparisonSliceDot' + this.#guid)
            .selectAll('circle')
            .data(comparisonSortedLeft.concat(comparisonSortedRight))
            .join('circle')
            .attr('cx', dta => -dta[2]) //negative value as expected to look along the kiln and then y needs to be negated
            .attr('cy', dta => this.#scatter.dim0Min - dta[1]) //negative value as svg is bottom up;
            .attr('r', 0.01)
            .style('fill', (dta, idx) => this.#colorScale(distScale[idx]));

        } 
        else
        {       
          d3.select('#comparisonSliceDot' + this.#guid)
            .selectAll('circle')
            .data(this.#comparisonScatter.pointData)
            .join('circle')
            .attr('cx', dta => -dta[2]) //negative value as expected to look along the kiln and then y needs to be negated
            .attr('cy', dta => this.#scatter.dim0Min - dta[1]) //negative value as svg is bottom up;
            .attr('r', 0.01)
            .style('fill', (dta, idx) => '#FF0000');
        }
 


      }

    }

    else {


      if (this.#footer) {
        const str = `Current position: ${this.#currentPosition.toFixed(1)} m , No values for this position`;
        d3.select(this.#footer).text(str);
      }
      d3.select('#sliceDot' + this.guid).selectAll('circle').remove();



    }
  }


  comparsionHeaderFetched(comparisonScatter) {

    this.#comparisonScatter = comparisonScatter;
    this.updatePosition();

  }
  compareWith(path) {
    this.#comparisonScatter = null;
    const comparisonScatter = new LES.Scatter(path);
    comparisonScatter.fetchHeader(scatter => { this.comparsionHeaderFetched(scatter); });
  }


  updateAcceptableThickness() {
  }


  onResize() {
    const rect = this.#target.getBoundingClientRect();
    this.#canvasHeight = rect.height;
    this.#canvasWidth = rect.width;

  }


  sliceHighlightHeight(x_pos, y_pos) {
    if (this.#scatter.pointData && this.#widthIndicator) {


      let activeSortedPointsLeft = this.pointsSortedLeft;
      let activeSortedPointsRight = this.pointsSortedRight;
      
      if (this.#widthIndicator.useComparison) {
        
        activeSortedPointsLeft = this.comparisonPointsSortedLeft;
        activeSortedPointsRight = this.comparisonPointsSortedRight;
      }



     
      const closestPointIdxLeft = Utils.binSearch(activeSortedPointsLeft, y_pos, 1);
      const closestPointIdxRight = Utils.binSearch(activeSortedPointsRight, y_pos, 1);

      
      const y_pos_same = this.#scatter.dim0Min-((x_pos<0)?activeSortedPointsLeft[closestPointIdxLeft][1]:activeSortedPointsRight[closestPointIdxRight][1]);
      const same_x_end_point = (x_pos<0)?activeSortedPointsLeft[closestPointIdxLeft][2]:activeSortedPointsRight[closestPointIdxRight][2];
      const y_pos_oppposite = this.#scatter.dim0Min-((x_pos>0)?activeSortedPointsLeft[closestPointIdxLeft][1]:activeSortedPointsRight[closestPointIdxRight][1]);
      const opposite_x_end_point = (x_pos>0)?activeSortedPointsLeft[closestPointIdxLeft][2]:activeSortedPointsRight[closestPointIdxRight][2];

      if (this.#widthIndicator.sameSide) {
        d3.select(`#verticalsliceSVG${this.#guid} > #sliceHeightIndicatorSame`)
          .attr('x1', -same_x_end_point)
          .attr('y1', y_pos_same)
          .attr('x2', 0)
          .attr('y2', y_pos_same);

        d3.select(`#verticalsliceSVG${this.#guid} > #sliceHeightIndicatorTextSame`)
          .attr('x', -same_x_end_point/2)
          .attr('y', y_pos_same-0.05)
          .attr('text-anchor','middle')
          .text(' ' + Math.abs((same_x_end_point*1000)).toFixed(0) + ' mm ');
  
      }
      if (this.#widthIndicator.oppositeSide) {
        d3.select(`#verticalsliceSVG${this.#guid} > #sliceHeightIndicatorOpposite`)
          .attr('x1', -opposite_x_end_point)
          .attr('y1', y_pos_oppposite)
          .attr('x2', 0)
          .attr('y2', y_pos_oppposite);

        d3.select(`#verticalsliceSVG${this.#guid} > #sliceHeightIndicatorTextOpposite`)
          .attr('x', -opposite_x_end_point/2)
          .attr('y', y_pos_oppposite-0.05)
          .attr('text-anchor','middle')
          .text(' ' + Math.abs((opposite_x_end_point*1000)).toFixed(0) + ' mm ');
  
      }
      if (this.#widthIndicator.oppositeSide) {
        d3.select(`#verticalsliceSVG${this.#guid} > #sliceHeightIndicatorOpposite`)
          .attr('x1', -opposite_x_end_point)
          .attr('y1', y_pos_oppposite)
          .attr('x2', -same_x_end_point)
          .attr('y2', y_pos_oppposite);

        d3.select(`#verticalsliceSVG${this.#guid} > #sliceHeightIndicatorTextWallToWall`)
          .attr('x', 0)
          .attr('y', y_pos_oppposite-0.2)
          .attr('text-anchor','middle')
          .text(Math.abs((( Math.abs(opposite_x_end_point) + Math.abs(same_x_end_point))*1000)).toFixed(0) + ' mm');
  
      }

      
    }


  }
  onMouseMove(event) {
    const loc = Utils.cursorPoint(event.clientX, event.clientY, this.#targetPt, this.#svg);
    this.sliceHighlightHeight(loc.x, this.#scatter.dim0Min -loc.y); //invert to get the correct position (svg coordinate system)
  }

  onMouseOut(event) {

  }


}