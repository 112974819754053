'use strict';

import * as Constants from './constants.js';

export class Location {

  #x;
  #y;
  #z;
  #angle;


  constructor(x = null, y = null, z = null, angle = 0) {
    this.#x = x;
    this.#y = y;
    this.#z = z;
    this.#angle = angle;
  }


  get x() { return this.#x; }
  get y() { return this.#y; }
  get z() { return this.#z; }
  get angle() { return this.#angle; }


}


export function selectColor(number) {
  const hue = number * -137.508; // use golden angle approximation
  return `hsl(${hue},50%,55%)`;
}


export function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num;
}



export function normalize(val, lower, upper) {
  return (val - lower) / (upper - lower);
}

export function clampAndNormalize(val, lower, upper) {
  const clamped = clamp(val, lower, upper);
  return (clamped - lower) / (upper - lower);
}


export function binAngleSearch(arr, angle) {
  let start = 0, end = arr.length - 1;
  while (start <= end) {
    let mid = Math.floor((start + end) / 2);
    if (arr[mid].angle === angle) return mid;
    else if (arr[mid].angle < angle)
      start = mid + 1;
    else
      end = mid - 1;
  }
  return (start > end) ? end : start;
}


export function binSearch(arr, value, sorted_field=0) {
  let start = 0, end = arr.length - 1;
  while (start <= end) {
    let mid = Math.floor((start + end) / 2);
    if (arr[mid][sorted_field] === value) return mid;
    else if (arr[mid][sorted_field] < value)
      start = mid + 1;
    else
      end = mid - 1;
  }
  end = (end<0)? 0: end;
  return (start > end) ? end : start;
}



export function cursorPoint(x, y, pt, element) {
  pt.x = x; pt.y = y;
  return pt.matrixTransform(element.getScreenCTM().inverse());
}

export let guid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};



export function debugLog() {

  if (LES_DEBUG) {
    console.log(...arguments);

  }

}

export function openInNewTab(url) {
  window.open(url, '_blank').focus();
}

export function range(start, stop, step) { 
  return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
}

export function average(array) {
  return array.reduce((a, b) => a + b) / array.length;
}


export function splitRegion(region, i)
{ 
  if(region.angle.start > region.angle.end) {
    let region1 = JSON.parse(JSON.stringify(region));
    region1.angle.end = 180;
    region1.region_id = i;
    let region2 = JSON.parse(JSON.stringify(region));
    region2.angle.start = -180;
    region2.region_id = i;
    return [region1, region2];
  } 
  else {
    let region1 = JSON.parse(JSON.stringify(region));
    region1.region_id = i;
    return region1;
  }
}