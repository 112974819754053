(function webpackUniversalModuleDefinition(root, factory) {
	//RHI Magnesita
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	//RHI Magnesita
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	//RHI Magnesita
	else if(typeof exports === 'object')
		exports["LES"] = factory();
	//RHI Magnesita
	else
		root["LES"] = factory();
})(self, () => {
return 