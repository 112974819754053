'use strict';

import * as styles from '../css/.module/heatmap.css';

import * as Utils from './utils.js';
import * as Constants from './constants.js';


import $ from 'jquery';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import * as chroma from 'chroma-js';
import * as d3 from 'd3';



export default class Heatmap {
  #data = null;
  #target;
  #vessel;
  #dataURL = null;
  #lowerCap;
  #upperCap;

  #drawLimitLayer;
  #drawAcceptablePlane;
  #dustSegments;

  #grid;

  #guid = Utils.guid();
  #acceptablePlanePositionTween = null;
  #positionMarkerTween = null;
  #comparisonMorphTween = null;
  #comparisonMorphGrade = { grade: 0 };


  #renderer;
  #scene;
  #camera;
  #container;
  #canvas;
  #spinner;
  #thicknessIndicator;
  #overlay;
  #vertical;
  #cmap = null;
  #comparisonCmap = null;
  #points = null;
  #pointSetGeometry = null;
  #onlyInBaseCMAPValue = null;
  #onlyInSubtractedCMAPValue = null;
  #invertColorScale = false;


  constructor(target, vessel, data, { lowerCap = null, upperCap = null, invertColorScale=false, dustSegments = null, drawLimitLayer = false, drawAcceptablePlane = true, vertical = false, onlyInBaseCMAPValue = null,
    onlyInSubtractedCMAPValue = null, grid = null}) {

    this.#target = target;
    this.#vessel = vessel;
    this.#grid = grid;

    this.#lowerCap = lowerCap;
    this.#upperCap = upperCap;

    this.#vertical = vertical;
    this.#drawLimitLayer = drawLimitLayer;
    this.#drawAcceptablePlane = drawAcceptablePlane;
    this.#dustSegments = dustSegments;
    this.#guid = Utils.guid();
    this.#onlyInBaseCMAPValue= onlyInBaseCMAPValue;
    this.#onlyInSubtractedCMAPValue= onlyInSubtractedCMAPValue;

    this.#invertColorScale=invertColorScale;


    target.innerHTML =
      `
    <div class="${styles.heatmapThicknessIndicator}" id="heatmapThicknessIndicator${this.#guid}"></div>
    <div class="${styles.heatmapContainer}" id="heatmapContainer${this.#guid}">
      <div class="${styles.heatmapSpinner}" id="heatmapSpinner${this.#guid}">
        <div class="spinner-border text-primary" style="width: 5vw; height: 5vw; margin-top: 7vw;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <canvas id="heatmapCanvas${this.#guid}" width="1200" height="300">
      </canvas>
      <svg class="${styles.heatmapOverlay}" width="1200" height="300" preserveAspectRatio="none" ></svg> 
    </div>
    `;
    this.#overlay = document.querySelector(`#heatmapContainer${this.#guid} > .${styles.heatmapOverlay}`);
    this.#container = document.getElementById('heatmapContainer' + this.#guid);
    this.#canvas = document.getElementById('heatmapCanvas' + this.#guid);
    this.#spinner = document.getElementById('heatmapSpinner' + this.#guid);
    this.#thicknessIndicator = document.getElementById('heatmapThicknessIndicator' + this.#guid);


    if (typeof data === 'string' || data instanceof String) {
      this.loadColorMap(data);
    }
    else if (data instanceof LES.CMAP) {
      this.setColorMap(data);
    }

  }

  get vessel() {
    return this.#vessel;
  }


  onSelectedKilnPositionChange(event) {


    if (event.detail.new_location != null) {

      let canvasPos = this.convertPhysicalPosToHeatmapPos(event.detail.new_location);


      if (this.#vertical) {
        this.#positionMarkerTween = new TWEEN.Tween(this.#positionMarkerTween._object)
          .to({ y: canvasPos.x }, 500)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .start();
      }
      else {
        this.#positionMarkerTween = new TWEEN.Tween(this.#positionMarkerTween._object)
          .to({ x: canvasPos.x }, 500)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .start();
      }

    }
  }

  onAcceptableThicknessChange() {

    if (this.#drawAcceptablePlane) {
      let target = { z: event.detail.new_thickness };
      this.#acceptablePlanePositionTween = new TWEEN.Tween(this.#acceptablePlanePositionTween._object)
        .to(target, 500)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
    }
  }

  drawGrid (){

    if (this.#grid){

      let x_lim = [this.#cmap.dim1Min, this.#cmap.dim1Max];
      let y_lim = [this.#cmap.dim0Min, this.#cmap.dim0Max];
      
      if (this.#vertical) {
        const _tmp = x_lim;
        x_lim = y_lim;
        y_lim = _tmp;
      }

      const cmap_x_span = x_lim[1] - x_lim[0];
      const cmap_y_span = y_lim[1] - y_lim[0];


      //this.#overlay.setAttribute('viewBox', `${x_lim[0]}, ${y_lim[0]}, ${x_lim[1] - x_lim[0]},  ${y_lim[1] - y_lim[0]}`);

      
      this.#overlay.setAttribute('viewBox', '0, 0, 100, 100');
      
      const convert_x = (value) => (value -x_lim[0])*100/cmap_x_span;
      const convert_y = (value) => (100 - (value-y_lim[0])*100/cmap_y_span);
      
      let x_range = null;
      let y_range = null;
      
      if (this.#grid.x) {
        const step = this.#grid.x.step || 1;
        const start = this.#grid.x.start || x_lim[0];
        const stop = this.#grid.x.stop || x_lim[1];

        x_range = Utils.range(start, stop, step);
        d3.select(this.#overlay)
          .selectAll(`.${styles.svgGridLineX}`)
          .data(x_range)
          .join('line')
          .attr('class', styles.svgGridLineX)
          .attr('x1', dta =>convert_x(dta))
          .attr('x2', dta =>convert_x(dta))
          .attr('y1', 0)
          .attr('y2', 100);


        if (this.#grid.x.showGridTickLabels) {
          d3.select(this.#overlay)
            .selectAll(`.${styles.svgGridTickLabelX}`)
            .data(x_range)
            .join('text')
            .attr('class', styles.svgGridTickLabelX)
            .attr('x', dta =>convert_x(dta))
            .attr('y', 100)
            .text(function (dta, idx) { return dta; });
        
        }
  
      }


      
      if (this.#grid.y) {
        const step = this.#grid.y.step || 1;
        const start = this.#grid.y.start || y_lim[0];
        const stop = this.#grid.y.stop || y_lim[1];
        y_range = Utils.range(start, stop, step);
        d3.select(this.#overlay)
          .selectAll(`.${styles.svgGridLineY}`)
          .data(y_range)
          .join('line')
          .attr('class', styles.svgGridLineY)
          .attr('y1', dta =>convert_y(dta))
          .attr('y2', dta =>convert_y(dta))
          .attr('x1', 0)
          .attr('x2', 100);

        if (this.#grid.y.showGridTickLabels) {
          d3.select(this.#overlay)
            .selectAll(`.${styles.svgGridTickLabelY}`)
            .data(y_range)
            .join('text')
            .attr('class', styles.svgGridTickLabelY)
            .attr('x', 0)
            .attr('y', dta =>(convert_y(dta)))
            .text(function (dta, idx) { return dta; });
          
        }
    
      }
      if (this.#grid.y && this.#grid.y && this.#grid.showValues) {
        let grid_cells = [];
        
        if (x_range.length > 1){
          for (let i = 0; i < x_range.length -1; i++){
            const x_start = x_range[i] ;
            const x_end = x_range[i+1];
            
            if (x_range.length > 1){
              for (let j = 0; j < y_range.length -1; j++){
                const y_start = y_range[j]; 
                const y_end = y_range[j+1];

                const midpoint = [(x_start + x_end)/2, (y_start + y_end)/2];

                const grid_cell = {top: y_start, bottom: y_end, left: x_start, right: x_end, midpoint: [convert_x(midpoint[0]) , convert_y(midpoint[1])]};
                let points = [];
                if (this.#vertical)
                  points = this.#cmap.getPointsForArea(x_start, x_end, y_start, y_end);
                else 
                  points = this.#cmap.getPointsForArea(y_start, y_end, x_start, x_end);
                grid_cell.value = points.reduce(((value, point) => point.value < value ? point.value: value), Number.MAX_VALUE);

                grid_cells.push(grid_cell);
              }            
            }
          }
        }

        d3.select(this.#overlay)
          .selectAll(`.${styles.svgGridValueLabel}`)
          .data(grid_cells)
          .join('text')
          .attr('class', styles.svgGridValueLabel)
          .attr('x', dta => dta.midpoint[0])
          .attr('y', dta => dta.midpoint[1])
          .text(dta => dta.value);
          
        

        
      }


    }

  }




  onResize() {


    let rect = this.#target.getBoundingClientRect();
    this.#canvas.width = rect.width;
    this.#canvas.height = rect.height;


    this.#overlay.setAttribute('width', rect.width );
    this.#overlay.setAttribute('height', rect.height);

    if (this.#cmap) {
      if (this.#renderer != null) {
        this.#renderer.setSize(this.#target.clientWidth, this.#target.clientHeight);
        if (this.material) {
          if (this.#vertical)
            this.material.size = (this.#target.clientWidth / this.#cmap.arrayDim0Size < 1) ? 1 : this.#target.clientWidth / this.#cmap.arrayDim0Size;
          else
            this.material.size = (this.#target.clientWidth / this.#cmap.arrayDim1Size < 1) ? 1 : this.#target.clientWidth / this.#cmap.arrayDim1Size;
          this.material.needsUpdate = true;
        }
      }
    }

  }

  convertCanvasPosToPhysicalPos(x, y) {

    let rect = this.#canvas.getBoundingClientRect();

    if (this.#vertical) {
      return {
        //this looks strange but is due to us having flipped the heatmap to vertical position
        'x': (1 - y / rect.height) * (this.#cmap.dim1Max - this.#cmap.dim1Min) + this.#cmap.dim1Min,
        'angle': (x / rect.width) * (this.#cmap.dim0Max - this.#cmap.dim0Min) + this.#cmap.dim0Min,
      };

    }
    else {
      return {
        'x': (x / rect.width) * (this.#cmap.dim1Max - this.#cmap.dim1Min) + this.#cmap.dim1Min,
        'angle': (y / rect.height) * (this.#cmap.dim0Max - this.#cmap.dim0Min) + this.#cmap.dim0Min
      };
    }
  }




  convertPhysicalPosToHeatmapPos(location) {

    const dim1 = Math.floor(this.#cmap.arrayDim1Size * Utils.clampAndNormalize(location.x, this.#cmap.dim1Min, this.#cmap.dim1Max));
    const dim0 = Math.floor(this.#cmap.arrayDim0Size * Utils.clampAndNormalize(location.angle, this.#cmap.dim0Min, this.#cmap.dim0Max));

    return {
      'x': dim1,
      'y': dim0
    };


  }






  onClick(event) {
    if (this.#cmap) {
      let x = event.offsetX;
      let y = event.offsetY;
      //Emit a selected position change event
      this.#vessel.setSelectedPosition(this.convertCanvasPosToPhysicalPos(x, y));
    }
  }


  onMouseMove(event) {

    if (this.#cmap) {
      let x = event.offsetX;
      let y = event.offsetY;
      //Emit a selected position change event
      let location = this.convertCanvasPosToPhysicalPos(x, y);
      this.#vessel.setHoveredPosition(location);



      let value = this.#cmap.getValueAtPhysicalPos(location);

      //thickness, x_pos, event
      let posInfoStr = '';

      if (value != this.#cmap.invalidDepthVal) {
        posInfoStr += `<p class="${styles.hoverInfoElem}"> <b> ${value}  mm </b></p> `;
      }

      let brick = this.#vessel.getBrickRegionAtPosition(location);
      if (brick) {
        posInfoStr += `<p class="${styles.hoverInfoElem}">${brick.attributes.Brand}</p>
                    <p class="${styles.hoverInfoElem}">${brick.attributes.Comment}</p>
                    <p class="${styles.hoverInfoElem}"> Installed: ${brick.attributes.Installed} </p>`;

        try {
          let installedDate = new Date(brick.attributes.Installed);
          if (!isNaN(installedDate)) {
            let currentDate = new Date();
            let months = (currentDate.getFullYear() - installedDate.getFullYear()) * 12;
            months -= installedDate.getMonth();
            months += currentDate.getMonth();
            months = months <= 0 ? 0 : months;
            posInfoStr += `<p class="${styles.hoverInfoElem}"> Age: ${months} months </p>`;
          }
        }
        catch (e) {
          Utils.debugLog(e);

        }

      }
      if (posInfoStr != '') {

        this.#thicknessIndicator.style.top = `${event.clientY}px`;
        let rect = this.#canvas.getBoundingClientRect();


        if ((rect.width / 2 + rect.left) > event.clientX) {
          this.#thicknessIndicator.style.left = `${event.clientX + 10}px`;
        }
        else {
          this.#thicknessIndicator.style.left = `${event.clientX - this.#thicknessIndicator.clientWidth - 10}px`;
        }
        this.#thicknessIndicator.classList.add(styles.show);
        this.#thicknessIndicator.innerHTML = posInfoStr;

      }
      else {
        this.#thicknessIndicator.classList.remove(styles.show);
      }


    }
  }
  onMouseOut() {
    this.#vessel.setHoveredPosition(null);
    this.#thicknessIndicator.classList.remove(styles.show);

  }

  onHoveredKilnPositionChange() {

  }



  getShort(byteArray, bytePos) {

    let value = 0;
    if (this.littleEndian)
      value = byteArray[bytePos + 1] << 8 | byteArray[bytePos] << 0;
    else
      value = byteArray[bytePos] << 8 | byteArray[bytePos + 1] << 0;
    return value > 0x7FFF ? value - 0x10000 : value;
  }


  #generatePlaneGeometryAndMaterial(cmap, comparisonCmap) {

    const vertices = [];
    const colors = [];


    const max_cap_for_color = this.#upperCap === null ? cmap.valueMax: this.#upperCap;
    const min_cap_for_color = this.#lowerCap === null ? cmap.valueMin: this.#lowerCap;

    let viridis = chroma.scale('viridis');
    let dim1_proportion = 1;
    let dim0_proportion = 1;
    let df_x_max = this.#vessel.axisMax;
    viridis(0.25).hex();




    for (let dim0_array_pos = 0; dim0_array_pos < cmap.arrayDim0Size; dim0_array_pos++) {
      for (let dim1_array_pos = 0; dim1_array_pos < cmap.arrayDim1Size; dim1_array_pos++) {

        let point_thickness = cmap.getPoint(dim0_array_pos, dim1_array_pos);

        if (comparisonCmap) {

          let comparison_point_thickness = comparisonCmap.getPoint(dim0_array_pos, dim1_array_pos);

          if (comparison_point_thickness == comparisonCmap.invalidDepthVal || point_thickness == cmap.invalidDepthVal)
            point_thickness = cmap.invalidDepthVal;
          else
            point_thickness -= comparison_point_thickness;

        }




        let x = 0, y = 0;


        if (this.#vertical) {
          x = dim0_array_pos * dim0_proportion;
          y = dim1_array_pos - 1;
        }

        else {
          x = dim1_array_pos * dim1_proportion;
          y = cmap.arrayDim0Size - dim0_array_pos - 1;
        }

        let z = point_thickness + 2000;
        let r = 1.0;
        let g = 1.0;
        let b = 1.0;

        if (point_thickness == cmap.invalidDepthVal) {

          z = 4000;
        }
        else if (point_thickness == this.#onlyInBaseCMAPValue && this.#onlyInBaseCMAPValue !== null ) {
          z = 4000;
          r = 0.9;
          g = 0.83;
          b = 0.83;  

        }
        else if (point_thickness == this.#onlyInSubtractedCMAPValue && this.#onlyInSubtractedCMAPValue !== null) {
          z = 4000;
          r = 0.83;
          g = 0.83;
          b = 0.9; 
        }
        else {
          //clamp  a value between upper and lower thicness values
          let color_thickness = Utils.clamp(point_thickness, min_cap_for_color, max_cap_for_color);
          //linear color gradient between the upper and lower values
          color_thickness = Utils.normalize(color_thickness, min_cap_for_color, max_cap_for_color);
          let color_rgb = this.#invertColorScale ? viridis(1-color_thickness).rgb() : viridis(color_thickness).rgb(); 

          r = color_rgb[0] / 255;
          g = color_rgb[1] / 255;
          b = color_rgb[2] / 255;
        }



        if (this.#dustSegments != null) {
          for (let dustSegment of this.#dustSegments) {
            // lets colorize the dust segment a bit....
            if ((dim0_array_pos * 360 / cmap.arrayDim0Size - 180 > dustSegment['start_angle']) &&
              (dim0_array_pos * 360 / cmap.arrayDim0Size - 180 < dustSegment['end_angle']) &&
              (dim1_array_pos * dim1_proportion / cmap.arrayDim1Size * df_x_max > dustSegment['start_x']) &&
              (dim1_array_pos * dim1_proportion / cmap.arrayDim1Size * df_x_max < dustSegment['end_x'])) {
              r += .2; //color it it red
            }
          }
        }

        vertices.push(x, y, z);
        colors.push(r, g, b);

      }
    }

    return { vertices, colors };

  }

  setColorMap(cmap) {

    $(this.#spinner).fadeOut(1000);
    this.onResize();

    this.#cmap = cmap;
    this.#renderer = new THREE.WebGLRenderer({ canvas: this.#canvas, antialias: true, autoSize: true });
    this.#scene = new THREE.Scene();
    this.#scene.background = new THREE.Color(0xffffff);
    let acceptablePlaneGeometry;
    let positionMarkerGeometry;
    let acceptablePlane;

    if (this.#vertical) {
      this.#camera = new THREE.OrthographicCamera(0, this.#cmap.arrayDim0Size, this.#cmap.arrayDim1Size, 0, -6000, 6000);
      if (this.#drawAcceptablePlane) {
        acceptablePlaneGeometry = new THREE.PlaneGeometry(this.#cmap.arrayDim0Size, this.#cmap.arrayDim1Size, 1);
      }
      positionMarkerGeometry = new THREE.PlaneGeometry(this.#cmap.arrayDim1Size, 2, 1);
    }
    else {
      this.#camera = new THREE.OrthographicCamera(0, this.#cmap.arrayDim1Size, this.#cmap.arrayDim0Size, 0, -6000, 6000);
      if (this.#drawAcceptablePlane) {
        acceptablePlaneGeometry = new THREE.PlaneGeometry(this.#cmap.arrayDim1Size, this.#cmap.arrayDim0Size, 1);
      }
      positionMarkerGeometry = new THREE.PlaneGeometry(2, this.#cmap.arrayDim0Size, 1);
    }
    this.#camera.position.z = 6000;

    if (this.#drawAcceptablePlane) {

      let acceptablePlaneMaterial = new THREE.MeshBasicMaterial({ color: 0xED2C2A, });
      acceptablePlane = new THREE.Mesh(acceptablePlaneGeometry, acceptablePlaneMaterial);

      this.#acceptablePlanePositionTween = new TWEEN.Tween(acceptablePlane.position)
        .to({ 'z': this.#vessel.acceptableThickness }, 500)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();

    }


    if (this.#drawLimitLayer) {
      //Todo: Fix this
      Utils.debugLog('drawLimitLayer not implemented');
    }
    let positionMarkerMaterial = new THREE.MeshBasicMaterial({ color: 0xCCCCCC });
    let positionMarker = new THREE.Mesh(positionMarkerGeometry, positionMarkerMaterial);
    positionMarker.position.z = 5000;

    if (this.#vertical) {
      positionMarker.position.x = this.#cmap.arrayDim0Size / 2;
      positionMarker.position.y = 1000;
      this.#positionMarkerTween = new TWEEN.Tween(positionMarker.position)
        .to({ 'y': -10 }, 500)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();

      if (this.#drawAcceptablePlane) {
        acceptablePlane.position.y = this.#cmap.arrayDim1Size / 2;
        acceptablePlane.position.x = this.#cmap.arrayDim0Size / 2;
      }
    }

    else {
      positionMarker.position.y = this.#cmap.arrayDim0Size / 2;
      positionMarker.position.x = 1000;
      this.#positionMarkerTween = new TWEEN.Tween(positionMarker.position)
        .to({ 'x': -10 }, 500)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();

      if (this.#drawAcceptablePlane) {
        acceptablePlane.position.x = this.#cmap.arrayDim1Size / 2;
        acceptablePlane.position.y = this.#cmap.arrayDim0Size / 2;
      }
    }



    this.#scene.add(positionMarker);

    if (this.#drawAcceptablePlane) {
      this.#scene.add(acceptablePlane);
    }


    let { vertices, colors } = this.#generatePlaneGeometryAndMaterial(this.#cmap);

    this.#pointSetGeometry = new THREE.BufferGeometry();
    this.#pointSetGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    this.#pointSetGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    this.#pointSetGeometry.attributes.position.needsUpdate = true;




    let svgWidth = this.#target.clientWidth;
    let particleSize;
    if (this.#vertical)
      particleSize = (svgWidth / this.#cmap.arrayDim0Size < 1) ? 1 : svgWidth / this.#cmap.arrayDim0Size;
    else
      particleSize = (svgWidth / this.#cmap.arrayDim1Size < 1) ? 1 : svgWidth / this.#cmap.arrayDim1Size;

    this.material = new THREE.PointsMaterial({ vertexColors: true, size: particleSize });


    this.#points = new THREE.Points(this.#pointSetGeometry, this.material);

    this.#points.morphTargetInfluences = [0, 0];
    this.#points.morphTargetDictionary = { 'position': 0};
    this.#scene.add(this.#points);

    this.drawGrid();


    this.render();
    //now we are ready to start listening for events
    this.setupEventListeners();
  }


  setCaps(lowerCap = 0, upperCap = 200)
  {
    this.#lowerCap = lowerCap;
    this.#upperCap = upperCap;
  }



  setupEventListeners() {
    this.#vessel.addEventListener(Constants.LES_SELECTED_KILN_LOCATION_CHANGE, (event) => this.onSelectedKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_HOVERED_KILN_LOCATION_CHANGE, (event) => this.onHoveredKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_ACCEPTABLE_THICKNESS_CHANGE, (event) => this.onAcceptableThicknessChange(event));
    window.addEventListener('resize', (event) => this.onResize(event));
    this.#container.addEventListener('click', (event) => this.onClick(event));
    this.#container.addEventListener('mousemove', (event) => this.onMouseMove(event));
    this.#container.addEventListener('mouseout', (event) => this.onMouseOut(event));
  }

  render() {
    requestAnimationFrame(this.render.bind(this));
    TWEEN.update();
    this.#points.morphTargetInfluences[0] = this.#comparisonMorphGrade.grade;
    this.#renderer.render(this.#scene, this.#camera);
  }


  loadColorMap(path) {
    this.onResize();
    this.#dataURL = path;

    fetch(path)
      .then(response => response.arrayBuffer())
      .then(data => this.setColorMap(new LES.CMAP(data)));


  }

  


  setComparisonHeatmap(path) {

    fetch(path)
      .then(response => response.arrayBuffer())
      .then(data => this.comparisonColormapLoaded(data));



  }


  comparisonColormapLoaded(data) {

    this.#comparisonCmap = new LES.CMAP(data);



    let { vertices, colors } = this.#generatePlaneGeometryAndMaterial(this.#cmap, this.#comparisonCmap);
    //this.#comparisonMorphGrade.grade = 0

    this.#pointSetGeometry.morphAttributes.position = [];
    this.#pointSetGeometry.morphAttributes.position[0] = new THREE.Float32BufferAttribute(vertices, 3);
    this.#pointSetGeometry.morphAttributes.color = [];
    this.#pointSetGeometry.morphAttributes.color[0] = new THREE.Float32BufferAttribute(colors, 3);
    //this.#pointSetGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    //this.#pointSetGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    this.#pointSetGeometry.attributes.position.needsUpdate = true;
    this.#pointSetGeometry.attributes.color.needsUpdate = true;


    this.#comparisonMorphTween = new TWEEN.Tween(this.#comparisonMorphGrade)
      .to({ grade: 1.0 }, 5000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();



    // this.#comparisonMorphTween = new TWEEN.Tween(this.#points.morphTargetInfluences)
    //   .to({
    //     0: 1
    //   }, 1000)
    //   .delay(500)
    //   .yoyo(true)
    //   .repeat(Infinity)
    //   .start();

  }


}