import CMAP from "./cmap.js"

export default class CMAPCache {

  #cmaps = {}
  #memLimit
  #maxPreFetch
  #memUsage = 0
  #previousGet = 0

  constructor(cmaps, { memLimit = 5 * 1000 * 1000, maxPreFetch = 3 }) {

    if (cmaps instanceof String) {
      cmaps = this.#createCacheItemFromPath(cmaps)
    }

    for (const item of cmaps) {

      let date = new Date(item.scanned);
      let scanned = date.getMilliseconds();
      this.#cmaps[item.path] = { path: item.path, scanned: scanned, status: 0, data: null, latestGet: this.#previousGet++ }
    }


    this.#memLimit = memLimit
    this.#maxPreFetch = maxPreFetch
    this.#preFetchCMAPS(this.#cmaps)
  }

  #createCacheItemFromPath(path) {
    return [{ path: path, scanned: "1970-01-01", status: 0, data: null }]
  }

  #preFetchCMAPS(startCMAP) {
    this.get(startCMAP).then(cmap => {
      //get the cache item
      cacheItem = this.#cmaps[cmap.path]
      cacheItem.status = 2;
      cacheItem.cmap = cmap
    });
  }


  #purgeCache() {

    //Todo implemement
  }

  #cmapLoaded(data, cacheItem, resolve) {

    cacheItem.loading = 2
    let cmap = new CMAP(data, cacheItem.path)
    cacheItem.cmap = cmap
    resolve(cmap)
    this.#memUsage += cmap.memorySize

    if (this.#memUsage > this.#memLimit) {
      this.#purgeCache();

    }
  }

  get(path) {
    return new Promise((resolve, reject) => {
      let cacheItem = this.#cmaps[path]
      if (cacheItem) {

        // in order to keep track on when the cmap was most recently retrieved
        cacheItem.latestGet = this.#previousGet++;

        if (cacheItem.cmap) {
          resolve(cacheItem.cmap);
          return;
        }
        else {
          cacheItem.status = 1;
          fetch(path)
            .then(response => response.arrayBuffer())
            .then(data => this.#cmapLoaded(data, cacheItem, resolve));

        }
      }
      else {
        // path wasnt in the original set - but we are not stingy
        this.#cmaps[path] = this.#createCacheItemFromPath(path);
        this.#cmaps[path].latestGet = this.#previousGet++
      }

    }
    );



  }



}