'use strict';

import * as styles from '../css/.module/heatmap_3d.css';

import * as Utils from './utils.js';
import * as Constants from './constants.js';
import CMAPCache from './cmap_cache';


import $ from 'jquery';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as TWEEN from '@tweenjs/tween.js';
import * as chroma from 'chroma-js';



export default class Heatmap3D {
  #target;
  #vessel;

  //private properties
  #lowerCap;
  #upperCap;
  #drawLimitLayer;
  #drawAcceptablePlane;

  #timeScaling;
  #valueScaling;
  #animationTime; //holds the time the animation is showing
  #guid = Utils.guid();

  //tweens
  #acceptablePlanePositionTween = null;
  #positionMarkerTween = null;


  //three.js stuff
  #renderer;
  #scene;
  #camera;
  #cmapPlane;
  #box;
  #raycaster = new THREE.Raycaster(); // create once
  #controls;
  #clock = new THREE.Clock();

  //html elements
  #container;
  #canvas;
  #spinner;
  #thicknessIndicator;
  #footer;

  //cmap related
  #cmaps;
  #cmapCache;
  #currentCMAP;
  #nextCMAP;


  //to be removed
  #dustSegments;



  constructor(target, vessel, cmaps, { width = 0, height = 0, lowerCap = 0, upperCap = 220, physicalStart = 0, physicalEnd = 50, invalidDeptVal = 0,
    dustSegments = null, drawLimitLayer = false, drawAcceptablePlane = true, valueScaling = 10, timeScaling = 86400 * 10 /*10 days per second*/, footer = null }) {

    this.#target = target;
    this.#vessel = vessel;
    this.#cmaps = cmaps;
    this.#footer = footer;


    this.#lowerCap = lowerCap;
    this.#upperCap = upperCap;
    this.#drawLimitLayer = drawLimitLayer;
    this.#drawAcceptablePlane = drawAcceptablePlane;

    this.#timeScaling = timeScaling;
    this.#valueScaling = valueScaling;
    this.#guid = Utils.guid();

    this.#acceptablePlanePositionTween = null;
    this.#positionMarkerTween = null;

    target.innerHTML =
      `
    <div class="${styles.heatmapThicknessIndicator}" id="heatmapThicknessIndicator${this.#guid}"></div>
    <div class="${styles.heatmapContainer}" id="heatmapContainer${this.#guid}">
      <div class="${styles.heatmapSpinner}" id="heatmapSpinner${this.#guid}">
        <div class="spinner-border text-primary" style="width: 5vw; height: 5vw; margin-top: 7vw;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <canvas id="heatmapCanvas${this.#guid}" width="1200" height="300">
      </canvas>
    </div>
    `;
    this.#container = document.getElementById('heatmapContainer' + this.#guid);
    this.#canvas = document.getElementById('heatmapCanvas' + this.#guid);
    this.#spinner = document.getElementById('heatmapSpinner' + this.#guid);
    this.#thicknessIndicator = document.getElementById('heatmapThicknessIndicator' + this.#guid);

    this.loadColorMaps();

  }

  get vessel() {
    return this.#vessel;
  }


  onSelectedKilnPositionChange(event) {


    if (event.detail.new_location != null) {

      let canvasPos = this.convertPhysicalPosToHeatmapPos(event.detail.new_location);


      this.#positionMarkerTween = new TWEEN.Tween(this.#positionMarkerTween._object)
        .to({ x: canvasPos.x }, 500)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();

    }

    if (this.#footer) {
      const str = `Current position: ${event.detail.new_location.x.toFixed(1)} m `;
      this.#footer.textContent = str;
    }


  }

  onAcceptableThicknessChange(event) {
    if (this.#drawAcceptablePlane) {
      let target = { z: event.detail.new_thickness };
      this.#acceptablePlanePositionTween = new TWEEN.Tween(this.#acceptablePlanePositionTween._object)
        .to(target, 500)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
    }
  }




  onResize(event) {


    let rect = this.#target.getBoundingClientRect();
    this.#canvas.width = rect.width;
    this.#canvas.height = rect.height;

    if (this.#renderer != null) {
      this.#renderer.setSize(this.#target.clientWidth, this.#target.clientHeight);
    }

  }

  convertCanvasPosToPhysicalPos(x, y) {

    //todo implement raytrace based

    return 0;


  }

  getValueAtPhysicalPos(location) {

    if (this.#currentCMAP) {

      const dim1 = Math.floor(this.#currentCMAP.arrayDim1Size * Utils.clampAndNormalize(location.x, this.#currentCMAP.dim1Min, this.#currentCMAP.dim1Max));
      const dim0 = Math.floor(this.#currentCMAP.arrayDim0Size * Utils.clampAndNormalize(location.angle, this.#currentCMAP.dim0Min, this.#currentCMAP.dim0Max));
      return this.#currentCMAP.data[dim0 * this.#currentCMAP.arrayDim1Size + dim1];
    }
    return 0;
  }





  convertPhysicalPosToHeatmapPos(location) {

    const dim1 = Math.floor(this.#currentCMAP.arrayDim1Size * Utils.clampAndNormalize(location.x, this.#currentCMAP.dim1Min, this.#currentCMAP.dim1Max) - this.#currentCMAP.arrayDim1Size / 2);
    const dim0 = Math.floor(this.#currentCMAP.arrayDim0Size * Utils.clampAndNormalize(location.angle, this.#currentCMAP.dim0Min, this.#currentCMAP.dim0Max) - this.#currentCMAP.arrayDim0Size / 2);

    return {
      'x': dim1,
      'y': dim0
    };


  }


  frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera) {
    const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
    const halfFovY = THREE.MathUtils.degToRad(camera.fov * .5);
    const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
    // compute a unit vector that points in the direction the camera is now
    // in the xy plane from the center of the box
    const direction = (new THREE.Vector3())
      .subVectors(camera.position, boxCenter)
      .multiply(new THREE.Vector3(1, 1, 1))
      .normalize();

    // move the camera to a position distance units way from the center
    // in whatever direction the camera was from the center already
    camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

    // pick some near and far values for the frustum that
    // will contain the box.
    camera.near = boxSize / 100;
    camera.far = boxSize * 100;

    camera.updateProjectionMatrix();

    // point the camera to look at the center of the box
    camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
  }



  onClick(event) {
    // the following line would stop any other event handler from firing
    // (such as the mouse's TrackballControls)
    event.preventDefault();
    let mouse = new THREE.Vector2(); // create once


    let rect = event.target.getBoundingClientRect();
    let x = event.clientX - rect.left; //x position within the element.
    let y = event.clientY - rect.top; //y position within the element.


    mouse.x = (x / this.#renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = - (y / this.#renderer.domElement.clientHeight) * 2 + 1;



    this.#raycaster.setFromCamera(mouse, this.#camera);

    let intersects = this.#raycaster.intersectObjects(this.#scene.children, true);

    // if there is one (or more) intersections
    if (intersects.length > 0) {
      const intersect = intersects[0];

      const dim1_physical = this.#currentCMAP.dim1Max * Utils.clampAndNormalize(intersect.point.x, -this.#currentCMAP.arrayDim1Size / 2, this.#currentCMAP.arrayDim1Size / 2) + this.#currentCMAP.dim1Min;
      let loc = new Utils.Location(dim1_physical,
        null,
        null);
      this.#vessel.setSelectedPosition(loc);
    }


  }


  onMouseMove(event) {

    if (this.#currentCMAP.data) {
      let x = event.offsetX;
      let y = event.offsetY;
      //Emit a selected position change event
      let location = this.convertCanvasPosToPhysicalPos(x, y);
      this.#vessel.setHoveredPosition();



      let value = this.getValueAtPhysicalPos(location);

      //thickness, x_pos, event
      let posInfoStr = '';
      if (value != this.#currentCMAP.invalidDepthVal) {
        posInfoStr += `<p class="${styles.hoverInfoElem}"> <b> ${value}  mm </b></p> `;
      }
      let brick = this.#vessel.getBrickRegionAtPosition(location);
      if (brick) {
        posInfoStr += `<p class="${styles.hoverInfoElem}">${brick.attributes.Brand}</p>
                    <p class="${styles.hoverInfoElem}">${brick.attributes.Comment}</p>
                    <p class="${styles.hoverInfoElem}"> Installed: ${brick.attributes.Installed} </p>`;

        try {
          let installedDate = new Date(brick.attributes.Installed);
          if (!isNaN(installedDate)) {
            let currentDate = new Date();
            let months = (currentDate.getFullYear() - installedDate.getFullYear()) * 12;
            months -= installedDate.getMonth();
            months += currentDate.getMonth();
            months = months <= 0 ? 0 : months;
            posInfoStr += `<p class="${styles.hoverInfoElem}"> Age: ${months} months </p>`;
          }
        }
        catch (e) {
          Utils.debugLog(e);

        }


        this.#thicknessIndicator.style.top = `${event.clientY}px`;
        let rect = this.#canvas.getBoundingClientRect();


        if ((rect.width / 2 + rect.left) > event.clientX) {
          this.#thicknessIndicator.style.left = `${event.clientX + 10}px`;
        }
        else {
          this.#thicknessIndicator.style.left = `${event.clientX - this.#thicknessIndicator.clientWidth - 10}px`;
        }
        this.#thicknessIndicator.classList.add(styles.show);
        this.#thicknessIndicator.innerHTML = posInfoStr;

      }
      else {
        this.#thicknessIndicator.classList.remove(styles.show);
      }


    }
  }
  onMouseOut(event) {
    this.#vessel.setHoveredPosition(null);
    this.#thicknessIndicator.classList.remove(styles.show);

  }

  onHoveredKilnPositionChange(event) {

  }



  #generatePlaneGeometryAndMaterial(cmap, pos_array) {

    const colors = [];
    let dim1_proportion = 1;
    let dim0_proportion = 1;
    let df_x_max = this.#vessel.axisMax;
    const max_cap_for_color = this.#upperCap;
    const min_cap_for_color = this.#lowerCap;
    const viridis = chroma.scale('viridis');


    // just so taht we have a defalt value
    let prev_z = min_cap_for_color;

    const vertices = [];
    for (let dim0_array_pos = 0; dim0_array_pos < cmap.arrayDim0Size; dim0_array_pos++) {
      for (let dim1_array_pos = 0; dim1_array_pos < cmap.arrayDim1Size; dim1_array_pos++) {

        let point_thickness = cmap.data[dim0_array_pos * cmap.arrayDim1Size + dim1_array_pos];

        let z = point_thickness;
        let r = 1.0;
        let g = 1.0;
        let b = 1.0;
        let a = 1.0;


        if (point_thickness == cmap.invalidDepthVal) {
          a = 0;
          z = prev_z;
        }
        else {
          //clamp  a value between upper and lower thickness values
          let color_thickness = Utils.clampAndNormalize(point_thickness, min_cap_for_color, max_cap_for_color);
          let color_rgb = viridis(color_thickness).rgb();
          r = color_rgb[0] / 255;
          g = color_rgb[1] / 255;
          b = color_rgb[2] / 255;
          a = 1;
          prev_z = z;

        }



        if (this.#dustSegments != null) {
          for (let dustSegment of this.#dustSegments) {
            // lets colorize the dust segment a bit....
            if ((dim0_array_pos * 360 / cmap.arrayDim0Size - 180 > dustSegment['start_angle']) &&
              (dim0_array_pos * 360 / cmap.arrayDim0Size - 180 < dustSegment['end_angle']) &&
              (dim1_array_pos * dim1_proportion / cmap.arrayDim1Size * df_x_max > dustSegment['start_x']) &&
              (dim1_array_pos * dim1_proportion / cmap.arrayDim1Size * df_x_max < dustSegment['end_x'])) {
              r += .2; //color it it red
            }
          }
        }

        pos_array[3 * (dim0_array_pos * cmap.arrayDim1Size + dim1_array_pos) + 2] = z / this.#valueScaling;
        colors.push(r, g, b, a);

      }
    }





    return colors;



  }

  getShort(byteArray, bytePos) {

    let value = 0;
    if (this.littleEndian)
      value = byteArray[bytePos + 1] << 8 | byteArray[bytePos] << 0;
    else
      value = byteArray[bytePos] << 8 | byteArray[bytePos + 1] << 0;
    return value > 0x7FFF ? value - 0x10000 : value;
  }

  colormapLoaded(cmap) {

    $(this.#spinner).fadeOut(1000);
    this.onResize();

    this.#currentCMAP = cmap;



    this.#renderer = new THREE.WebGLRenderer({ canvas: this.#canvas, antialias: true, autoSize: true });
    this.#scene = new THREE.Scene();
    this.#scene.background = new THREE.Color('white');

    const fov = 45;
    const aspect = 2; // the canvas default
    const near = 100;
    const far = 10000;
    this.#camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    this.#camera.up.set(0, 0, 1);

    this.#controls = new OrbitControls(this.#camera, this.#canvas);
    //    this.#controls.enableDamping = true;
    //    this.#controls.screenSpacePanning = true;
    //    this.#controls.enablePan = true;
    this.#controls.target.set(0, 0, 0);
    this.#controls.update();



    if (this.#drawAcceptablePlane) {

      const acceptablePlaneGeometry = new THREE.PlaneGeometry(this.#currentCMAP.arrayDim1Size, this.#currentCMAP.arrayDim0Size, 1);
      const acceptablePlaneMaterial = new THREE.MeshBasicMaterial({ color: 0xED2C2A, transparent: true, side: THREE.DoubleSide, opacity: 0.5 });
      const acceptablePlane = new THREE.Mesh(acceptablePlaneGeometry, acceptablePlaneMaterial);
      acceptablePlane.position.x = 0;
      acceptablePlane.position.y = 0;


      this.#acceptablePlanePositionTween = new TWEEN.Tween(acceptablePlane.position)
        .to({ 'z': this.#vessel.acceptableThickness }, 500)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();


      this.#scene.add(acceptablePlane);
    }


    if (this.#drawLimitLayer) {
      //Todo: Fix this
      Utils.debugLog('drawLimitLayer not implemented');
    }



    const positionMarkerGeometry = new THREE.BoxGeometry(4, this.#currentCMAP.arrayDim0Size, (this.#upperCap - this.#lowerCap + 40) / this.#valueScaling);
    const positionMarkerMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true, side: THREE.DoubleSide, opacity: 0.2 });
    const positionMarker = new THREE.Mesh(positionMarkerGeometry, positionMarkerMaterial);

    positionMarker.position.z = (this.#lowerCap + 150) / this.#valueScaling;

    this.#positionMarkerTween = new TWEEN.Tween(positionMarker.position)
      .to({ 'x': -10 }, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();




    this.#scene.add(positionMarker);




    const planeBufferGeometry = new THREE.PlaneBufferGeometry(cmap.arrayDim1Size, cmap.arrayDim0Size, cmap.arrayDim1Size - 1, cmap.arrayDim0Size - 1);

    // support morphing between scans


    const pos_array = planeBufferGeometry.attributes.position.array;
    const colors = this.#generatePlaneGeometryAndMaterial(this.#currentCMAP, pos_array);


    //create a copy of the pos array
    if (this.#cmaps.length > 1) {
      planeBufferGeometry.morphAttributes.position = [];
      const next_pos_array = [...pos_array];
      const next_colors = this.#generatePlaneGeometryAndMaterial(this.#nextCMAP, next_pos_array);
      //morph end
      planeBufferGeometry.morphAttributes.position[0] = new THREE.Float32BufferAttribute(next_pos_array, 3);
    }
    planeBufferGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 4));
    planeBufferGeometry.attributes.position.needsUpdate = true;
    planeBufferGeometry.computeVertexNormals();


    let material = new THREE.MeshBasicMaterial({
      vertexColors: true, transparent: true,
      vertexAlphas: true, side: THREE.DoubleSide
    });



    this.#cmapPlane = new THREE.Mesh(planeBufferGeometry, material);
    this.#scene.add(this.#cmapPlane);



    this.#box = new THREE.Box3().setFromObject(this.#cmapPlane);

    const boxSize = this.#box.getSize(new THREE.Vector3()).length();
    const boxCenter = this.#box.getCenter(new THREE.Vector3());
    const new_camera_pos = this.#box.getCenter(new THREE.Vector3()).add(new THREE.Vector3(0, -300, 200));
    this.#camera.position.copy(new_camera_pos);

    // set the camera to frame the box
    //this.frameArea(boxSize * 0.8, boxSize, boxCenter, this.#camera);

    // update the Trackball controls to handle the new size
    this.#controls.maxDistance = boxSize * 10;
    this.#controls.target.copy(boxCenter);
    this.#controls.update();





    this.render(1);


    //now we are ready to start listening for events
    this.setupEventListeners();



  }



  setupEventListeners() {
    this.#vessel.addEventListener(Constants.LES_SELECTED_KILN_LOCATION_CHANGE, (event) => this.onSelectedKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_HOVERED_KILN_LOCATION_CHANGE, (event) => this.onHoveredKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_ACCEPTABLE_THICKNESS_CHANGE, (event) => this.onAcceptableThicknessChange(event));
    window.addEventListener('resize', (event) => this.onResize(event));
    this.#container.addEventListener('click', (event) => this.onClick(event));
    this.#container.addEventListener('mousemove', (event) => this.onMouseMove(event));
    this.#container.addEventListener('mouseout', (event) => this.onMouseOut(event));
  }


  render(timestamp) {
    requestAnimationFrame((timestamp) => this.render(timestamp));
    TWEEN.update();
    if (this.#cmaps.length > 1) {
      this.#cmapPlane.morphTargetInfluences[0] = Math.min(this.#clock.getElapsedTime() / 10, 1);
    }
    this.#renderer.render(this.#scene, this.#camera);
  }


  loadColorMaps() {

    this.#cmapCache = new CMAPCache(this.#cmaps, {});

    if (this.#cmaps.length > 1) {
      let firstScanDate = new Date(this.#cmaps[0].scanned);
      let firstScanned = firstScanDate.getMilliseconds();
      this.#animationTime = firstScanned;

      this.#cmapCache.get(this.#cmaps[1].path).then((cmap) => {
        this.#nextCMAP = cmap;
        this.#cmapCache.get(this.#cmaps[0].path).then((cmap) => {
          this.colormapLoaded(cmap);
        });
      });
    }
    else {
      this.#cmapCache.get(this.#cmaps[0].path).then((cmap) => { this.colormapLoaded(cmap); });
    }

    this.onResize();


  }


}
