import math, { row } from 'mathjs';
import * as Utils from './utils.js';

export default class CMAP {

  //from cmap.py

  #CMAPVersion;
  #arrayDim0Size;
  #arrayDim1Size;
  #unit0;
  #unit1;
  #unitValue;
  #invalidDepthVal;
  #dim0Min;
  #dim0Max;
  #dim1Min;
  #dim1Max;
  #valueMin;
  #valueMax;
  #data;
  #path;


  #onlyInSubtractedValue = null;
  #onlyInThisValue= null;

  constructor(arrayBuffer, path) {

    let byteArray = new Uint8Array(arrayBuffer);

    if (byteArray.length < 17) {
      //wont fit the header nor for new file format nor relevant data for old format
      throw new Error('cmap too short');
    }

    // Oscar Fogelbergs magic file format header  - allows the checking of endianness as well so in the futurue we can support that
    if ((byteArray[0] == 0xF0 && byteArray[1] == 0x0F) || (byteArray[0] == 0x0F && byteArray[1] == 0xF0)) {

      this.littleEndian = (byteArray[0] == 0xF0 && byteArray[1] == 0x0F);


      //check version
      const CMAPVersion = new Int16Array(arrayBuffer, 2, 1);
      this.#CMAPVersion =CMAPVersion;
      if (1 > this.#CMAPVersion > 2) {
        throw new Error('cmap file version not supported ');
      }


      let headerLength = 11; // length in number of short ints
      let header = new Int16Array(arrayBuffer, 2, headerLength);


      const resolution_0 = header[1];
      const resolution_1 = header[2];
      const unit_0 = header[3];
      const unit_1 = header[4];
      const unit_value = header[5];
      const nan_replace = header[6];
      const dim_0_min = header[7];
      const dim_0_max = header[8];
      const dim_1_min = header[9];
      const dim_1_max = header[10];


      //really add a value max and min as well for version 1 cmaps  we simply set this to 0
      let value_min = 0;
      let value_max = 0;

      if (this.#CMAPVersion > 1) {
        // Added the value min and max in version 2
        headerLength = 13; // length in number of short ints
        let header = new Int16Array(arrayBuffer, 2, headerLength);
        value_min = header[11];
        value_max = header[12];
      }
    

      //correponding component properties
      this.#arrayDim0Size = resolution_0;
      this.#arrayDim1Size = resolution_1;

      this.#unit0 = unit_0;
      this.#unit1 = unit_1;
      this.#unitValue = unit_value;

      //TODO: A lot of hardcoded assumptions here that needs to be improved on to support the units and types of heatmaps
      //here we are assuming angle and that this should be devided by 10... must abide by the rules of the
      this.#dim0Min = dim_0_min / 10;
      this.#dim0Max = dim_0_max / 10;
      // and dim1 is in meters...
      this.#dim1Min = dim_1_min / 100;
      this.#dim1Max = dim_1_max / 100;




      this.#invalidDepthVal = nan_replace;


      this.#data = new Int16Array(arrayBuffer, headerLength * 2, (byteArray.length - headerLength * 2) / 2);
      this.#path = path;

      if (this.#CMAPVersion == 1) {
        var valueMax = Number.MIN_VALUE, valueMin = Number.MAX_VALUE;
        for (var i = 0, len=this.#data.length; i < len; i++) {
          if (this.#data[i] != this.#invalidDepthVal) {
            if (this.#data[i] > valueMax) valueMax = this.#data[i];
            if (this.#data[i] < valueMin) valueMin = this.#data[i];
          }
        }
        this.#valueMin = valueMin;
        this.#valueMax = valueMax;  
      }
      else {
        this.#valueMin = value_min;
        this.#valueMax = value_max;
      }
    }
    else {
      this.#data = new Int16Array(arrayBuffer, 0, byteArray.length / 2);
    }

  }
  get arrayDim0Size() { return this.#arrayDim0Size; }
  get arrayDim1Size() { return this.#arrayDim1Size; }
  get unit0() { return this.#unit0; }
  get unit1() { return this.#unit1; }
  get unitValue() { return this.#unitValue; }
  get invalidDepthVal() { return this.#invalidDepthVal; }
  get dim0Min() { return this.#dim0Min; }
  get dim0Max() { return this.#dim0Max; }
  get dim1Min() { return this.#dim1Min; }
  get dim1Max() { return this.#dim1Max; }
  get valueMin() { return this.#valueMin; }
  get valueMax() { return this.#valueMax; }

  get data() { return this.#data; }
  get path() { return this.#path; }

  get memorySize() {
    return this.arrayDim0Size * this.arrayDim1Size * 2 + 22;
  }

  getPoint(row, col) {
    return this.#data[row * this.#arrayDim1Size + col];

  }

  getValueAtPhysicalPos(location) {
    let [dim0, dim1] = this.convertPhyicalPosToRowAndColumn(location.x, location.angle);

    return this.getPoint(dim0, dim1);
  }


  
  convertRowAndColumnToPhyicalPos(row, col ){

    const dim0 = col/this.arrayDim0Size * (this.dim0Max - this.dim0Min) + this.dim0Min;
    const dim1 = row/this.arrayDim1Size * (this.dim1Max - this.dim1Min) + this.dim1Min; 
    return [dim0, dim1];


  }


   
  convertPhyicalPosToRowAndColumn(row, col){

    const dim1 = Math.floor(this.arrayDim1Size * Utils.clampAndNormalize(row, this.dim1Min, this.dim1Max));
    const dim0 = Math.floor(this.arrayDim0Size * Utils.clampAndNormalize(col, this.dim0Min, this.dim0Max));
    return [dim0, dim1];


  }


  convertValueToRowAndColumn(valueDim0, valueDim1){


    let row = Math.round(this.arrayDim0Size*(valueDim0 - this.dim0Min)/(this.dim0Max - this.dim0Min));
    row = (row < 0)? 0 : row;
    row = (row >= this.arrayDim0Size)? this.arrayDim0Size-1 :row;

    let col = Math.round(this.arrayDim1Size*(valueDim1 - this.dim1Min)/(this.dim1Max - this.dim1Min));
    col = (col < 0)? 0 : col;
    col = (col >= this.arrayDim1Size)? this.arrayDim1Size-1 :col;


    return [row, col];


  }


  getPointsForArea(minValueDim0, maxValueDim0, minValueDim1, maxValueDim1) {
    const minValues = this.convertValueToRowAndColumn(minValueDim0, minValueDim1);
    const maxValues = this.convertValueToRowAndColumn(maxValueDim0, maxValueDim1);
    return this.getPoints(minValues[0], maxValues[0], minValues[1], maxValues[1]);
  }

  getPoints(minRow, maxRow, minCol, maxCol) {
    let points = [];
    for (let row = minRow; row < maxRow; row++) {
      for (let col = minCol; col < maxCol; col++) {
        const pointValue = this.getPoint(row, col);
        if (pointValue != this.#invalidDepthVal && 
            pointValue != this.#onlyInThisValue && 
            pointValue != this.#onlyInSubtractedValue){
          let [dim0, dim1] = this.convertRowAndColumnToPhyicalPos(col, row);
          points.push({value: pointValue, dim1: dim1 , dim0: dim0});
        }
      }
    }
    return points;
  }




  getMaxPointForArea(minValueDim0, maxValueDim0, minValueDim1, maxValueDim1) {
    const minValues = this.convertValueToRowAndColumn(minValueDim0, minValueDim1);
    const maxValues = this.convertValueToRowAndColumn(maxValueDim0, maxValueDim1);
    return this.getMaxPoint(minValues[0], maxValues[0], minValues[1], maxValues[1]);
  }



  getMaxPoint(minRow, maxRow, minCol, maxCol) {
    let maximum = Number.MIN_VALUE;
    for (let row = minRow; row < maxRow; row++) {
      for (let col = minCol; col < maxCol; col++) {
        const pointValue = this.getPoint(row, col);
        if (pointValue != this.#invalidDepthVal && pointValue != this.#onlyInThisValue && pointValue != this.#onlyInSubtractedValue)
          maximum = (pointValue > maximum) ? pointValue : maximum;
      }
    }
    return maximum;
  }



  subtract(cmap, thisLast = false, onlyInThisValue = null, onlyInSubtractedValue =null) {

    this.#onlyInThisValue = onlyInThisValue || -32767;
    this.#onlyInSubtractedValue = onlyInSubtractedValue || -32766;

    //check that they are indeed compatible more checks naturally possible

    const multyplier = thisLast ? -1 : 1;

    if ((this.arrayDim0Size != cmap.arrayDim0Size) || (this.arrayDim1Size != cmap.arrayDim1Size)) {

      throw ('incompatible CMAPS');
    }

    this.#data = this.data.map((item, index) => {
      if (item == this.invalidDepthVal && cmap.data[index] == cmap.invalidDepthVal)
        return this.invalidDepthVal;
      else if (item == this.invalidDepthVal){
        if (onlyInThisValue!==null)
          return onlyInThisValue;
        else 
          return this.invalidDepthVal;
      } 
      else if (cmap.data[index] == cmap.invalidDepthVal) {
        if (onlyInSubtractedValue!==null)
          return onlyInSubtractedValue;
        else 
          return this.invalidDepthVal;
      }
      else
        return multyplier * (item - cmap.data[index]);
    });

    //really should have a max min data that we could diff as well

  }

}
