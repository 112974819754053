'use strict';
import * as styles from '../css/.module/vertical_wear_timeline.css';

import kilnDriveImg from '../assets/kilndrive.svg';
import tyreImg from '../assets/tyre.svg';
import panoramaIconImg from '../assets/panoramaicon.svg';

import * as Utils from './utils.js';
import * as Constants from './constants.js';
import ContextMenu from './context_menu.js';

import $ from 'jquery';
import * as d3 from 'd3';
import * as TWEEN from '@tweenjs/tween.js';


export default class VerticalWearTimeline {
  #target;
  #vessel;
  #guid;
  #footer;
  #svg;
  #kilnSvgPt;
  #positionMarkerTweenValue = -10;
  #brickInfoIndicator;
  #contextMenu;
  #timeIndicationWidth = 10
  #timeSlotHeight = 10


  constructor(target, vessel, sections, { footer = null }) {

    this.#target = target;
    this.#vessel = vessel;
    this.#guid = Utils.guid();
    this.#footer = footer;
    this.#target.innerHTML =
      ` <svg id="verticalWearSVG${this.#guid}" width="100%" height="100%" viewBox="-${this.#timeIndicationWidth}, 0, ${100 + this.#timeIndicationWidth}, 50">


            <defs>
            <linearGradient id="vert1" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
              <stop offset="50%" style="stop-color:rgb(255,0,0);stop-opacity:0.5" />
              <stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
            </linearGradient>

            <radialGradient id="rad1">
            <stop offset="10%" stop-color="gold" />
            <stop offset="95%" stop-color="red" />
            </radialGradient>
          </defs>


          <g transform="translate(-${this.#timeIndicationWidth / 2}, ${this.#timeSlotHeight / 2})">
          <circle class="${styles.timeIndicator}"  r="${this.#timeSlotHeight / 2.5}"/>
          <text class="${styles.timeIndicatorText}">
          <tspan x="0" dy="-2" dominant-baseline="central">Jan</tspan>
          <tspan x="0" dy="1.3em" dominant-baseline="central">2021</tspan>
          </text>
          </g>




          <rect class="${styles.mantleSection}" x=0 y=0  height=${this.#timeSlotHeight} width="100"/>
          <rect class="${styles.brickSection}" x=0 y=0 height=${this.#timeSlotHeight} width="20" fill="url(#vert1)"
        </svg>
      `;

    const menuItems = [
      {
        content: 'Open Panorama Image at position',
        events: {
          click: (event) => this.openPanoramaAtPosition(this.#contextMenu.clientX, this.#contextMenu.clientY)
        }
      },
      {
        content: 'Open scan at position',
        events: {
          click: (event) => this.openScanAtPosition(this.#contextMenu.clientX, this.#contextMenu.clientY)
        }
      },

      { content: 'Open ovality measurement' },
      // {
      //   content: `${deleteIcon}Delete`,
      //   divider: 'top'
      // }
    ];

    // this.#contextMenu = new ContextMenu({
    //   target: this.#target,
    //   mode: 'light',
    //   menuItems
    // });
    // this.#contextMenu.init();
    this.#svg = document.getElementById(`verticalWearSVG${this.#guid}`);
    this.#brickInfoIndicator = document.getElementById(`brickInfoIndicator${this.#guid}`);
    //this.#svg.setAttribute('viewBox', `-${this.#vessel.axisMax * this.#timeIndicationRatio}, 0 , ${this.#vessel.axisMax * (1 + this.#timeIndicationRatio)}, ${this.#timeSlotHeight}`);
    //this.setupVesselProfile();
    this.setUpEventListeners();

  }



  setupVesselProfile() {
    d3.select(this.#svg).select('#svgKilnBrickRegions')
      .selectAll('rect')
      .data(this.#vessel.brickTypes)
      .enter()
      .append('rect')
      .attr('x', dta => dta.start)
      .attr('y', -5)
      .attr('width', dta => (dta.end - dta.start))
      .attr('height', 10)
      .attr('fill', dta => dta.color)
      .attr('class', styles.svgBrickRegion)
      .attr('id', (dte, i) => 'svg-brick-region-' + i)
      .attr('brick_type_id', (dte, i) => i)
      .attr('clip-path', 'url(#kiln_clip_path)')
      .on('click', (event) => this.kilnSVGClick(event))
      .on('mousemove', (event) => this.kilnSVGMouseMove(event))
      .on('mouseout', (event) => this.kilnSVGMouseOut(event));


    d3.select(this.#svg).select('#svgKilnComponents')
      .selectAll('rect')
      .data(this.#vessel.tyres)
      .enter()
      .append('use')
      .attr('x', dta => dta.start)
      .attr('y', 0)
      .attr('xlink:href', '#svgTyre')
      .attr('pointer-events', 'none');

    d3.select(this.#svg).select('#svgKilnComponents')
      .selectAll('rect')
      .data(this.#vessel.kilnDrives)
      .enter()
      .append('use')
      .attr('x', dta => dta.start)
      .attr('y', 0)
      .attr('xlink:href', '#svgKilndrive')
      .attr('pointer-events', 'none');

    d3.select(this.#svg).select('#svgKilnComponents')
      .selectAll('rect')
      .data(this.#vessel.panoramas)
      .enter()
      .append('use')
      .attr('x', dta => dta.fields.position)
      .attr('y', 0)
      .attr('xlink:href', '#svgPanoramaIcon')
      .attr('pointer-events', 'none');

    let prevPos = [0, 0];
    let pathString = 'm 0 0 ';


    for (let pos of this.#vessel.kilnProfile) {
      let x = (pos[0] - prevPos[0]);
      let y = -(pos[1] - prevPos[1]);

      pathString += ` l${x}  ${y}`;
      prevPos = pos;
    }

    //start heading back by moving down 2x the current vertical position
    pathString += `l 0 ${2 * prevPos[1]}`;
    for (let i = this.#vessel.kilnProfile.length - 1; i >= 0; i--) {
      let pos = this.#vessel.kilnProfile[i];
      let x = (pos[0] - prevPos[0]);
      let y = (pos[1] - prevPos[1]);
      pathString += ` l${x}  ${y}`;
      prevPos = pos;
    }
    pathString += ' z ';



    d3.select(this.#svg).select('#kilnOutline')
      .append('path')
      .attr('d', pathString)
      .attr('style', 'fill:none;stroke:#000000;stroke-width:0.05;')
      .attr('id', 'generated_kiln_outline');


    this.#kilnSvgPt = this.#svg.createSVGPoint();
  }


  kilnSVGMouseMove(event) {
    let loc = Utils.cursorPoint(event.clientX, event.clientY, this.#kilnSvgPt, this.#svg);
    this.showPositionIndicator(0, loc.x, event);
    this.#vessel.setHoveredPosition(new Utils.Location(loc.x));

    if (this.#footer) {
      this.#footer.innerHTML = `Current: ${loc.x.toFixed(1)} m`;
    }

  }


  kilnSVGMouseOut(event) {
    this.#vessel.setHoveredPosition(null);

    this.#brickInfoIndicator.classList.remove(styles.show);
  }


  kilnSVGClick(event) {
    let loc = Utils.cursorPoint(event.clientX, event.clientY, this.#kilnSvgPt, this.#svg);
    this.#vessel.setSelectedPosition(new Utils.Location(loc.x));
  }


  onSelectedKilnPositionChange(event) {

    let selectedPosition = event.detail.new_position.x;

    d3.select(this.#svg).select('#selectedPositionMarker')
      .transition()
      .duration(500)
      .attr('transform', `translate(${(selectedPosition).toFixed(2)} 0)`);

    d3.select(this.#svg).select('#selectedPositionText').text(`${(selectedPosition).toFixed(2)} m`);


    let id = this.vessel.getBrickIdAtPosition(selectedPosition);
    let previousRegion = null;

    if (id != previousRegion) {
      if (previousRegion >= 0) {
        $('#brickRegionInfoElem' + previousRegion).hide();
      }

      $('#brickRegionInfoElem' + id).fadeIn('normal', function () {
        // Animation complete
      });

      previousRegion = id;
    }


  }


  showPositionIndicator(x_pos) {
    let posInfoStr = '';

    let brick = this.#vessel.getBrickRegionAtPosition(new Utils.Location(x_pos));
    if (brick) {
      posInfoStr += `<p class="${styles.hoverBrickInfoElem}">${brick.attributes.Brand}</p>
      <p class="${styles.hoverBrickInfoElem}">${brick.attributes.Comment}</p>
      <p class="${styles.hoverBrickInfoElem}"> Installed: ${brick.attributes.Installed} </p>`;

      try {
        let installedDate = new Date(brick.attributes.Installed);
        if (!isNaN(installedDate)) {
          let currentDate = new Date();
          let months = (currentDate.getFullYear() - installedDate.getFullYear()) * 12;
          months -= installedDate.getMonth();
          months += currentDate.getMonth();
          months = months <= 0 ? 0 : months;
          posInfoStr += `<p class="${styles.hoverBrickInfoElem}"> Age: ${months} months </p>`;
        }
      }
      catch (e) {

      }
    }

    this.#brickInfoIndicator.style.top = `${event.clientY}px`;
    if (window.innerWidth / 2 > event.clientX) {
      this.#brickInfoIndicator.style.left = `${event.clientX + 10}px`;
    }
    else {
      this.#brickInfoIndicator.style.left = `${event.clientX - this.#brickInfoIndicator.clientWidth - 10}px`;
    }
    this.#brickInfoIndicator.classList.add(styles.show);
    this.#brickInfoIndicator.innerHTML = posInfoStr;

  }


  onAcceptableThicknessChange(event) {
  }

  openPanoramaAtPosition(x, y) {
    let loc = Utils.cursorPoint(x, y, this.#kilnSvgPt, this.#svg);
    Utils.openInNewTab(`/panoramaimage-scan-view/${this.#vessel.currentScan}?meter=${loc.x}`);
  }



  openScanAtPosition(x, y) {

    let loc = Utils.cursorPoint(x, y, this.#kilnSvgPt, this.#svg);
    Utils.openInNewTab(`/scanview/${this.#vessel.currentScan}/?meter=${loc.x}`);


  }

  setUpEventListeners() {
    this.#vessel.addEventListener('LES_SELECTED_KILN_LOCATION_CHANGE', this.onSelectedKilnPositionChange);
    this.#vessel.addEventListener('LES_ACCEPTABLE_THICKNESS_CHANGE', this.onAcceptableThicknessChange);


    // this.#vessel.addEventListener('resize', this.onResize);


    // this.#target.addEventListener('click', this.onClick);
    // this.#target.addEventListener('mosemove', this.onMouseMove);
    // this.#target.addEventListener('mouseout', this.onMouseOut);
  }


}
