'use strict';
import * as styles from '../css/.module/cylindrical_vessel_overview.css';

import kilnDriveImg from '../assets/kilndrive.svg';
import tyreImg from '../assets/tyre.svg';
import panoramaIconImg from '../assets/panoramaicon.svg';

import * as Utils from './utils.js';
import * as Constants from './constants.js';
import ContextMenu from './context_menu.js';

import $ from 'jquery';
import * as d3 from 'd3';
import * as TWEEN from '@tweenjs/tween.js';


export default class CylindricalVesselOverview {
  #target;
  #vessel;
  #guid;
  #footer;
  #positionMarkerTween;
  #svg;
  #kilnSvgPt;
  #positionMarkerTweenValue = -10;
  #brickInfoIndicator;
  #contextMenu;
  #showVesselDiameter;


  #showContextMenu;

  constructor(target, vessel, { footer = null, showContextMenu = false, showVesselDiameter = false}) {

    this.#target = target;
    this.#vessel = vessel;
    this.#guid = Utils.guid();
    this.#footer = footer;
    this.#showContextMenu = showContextMenu;
    this.#showVesselDiameter = showVesselDiameter;


 
    this.#target.innerHTML =
      ` <svg id="kilnSVG${this.#guid}" width="100%" height="100%" viewBox="0, -4, 80, 8">
                  
                  <defs id="defs">
                    <clipPath clipPathUnits="userSpaceOnUse" id="kiln_clip_path">
                      <use href="#generated_kiln_outline" x="0" y="0" width="100%" height="100%" />
                    </clipPath>

                    <g id="svgKilndrive">
                      <image x="-1.56" y="-5.3" width="4" height="12" xlink:href="${kilnDriveImg}" />
                    </g>
                    <g id="svgTyre">
                      <image x="-1.96" y="-2.1" width="4" height="6" xlink:href="${tyreImg}" />
                    </g>
                    <g id="svgPanoramaIcon">
                      <image x="-0.5" y="0.3" width=".8" height=".8" xlink:href="${panoramaIconImg}" />
                    </g>
                  </defs>


                  <g id="kilnOutline"></g>
                  <g id=svgKilnBrickRegions></g>
                  <g id="svgKilnComponents"> </g>
                  <g id="selectedPositionMarker">
                    <line id="selectedPositionLine" x1="0" y1="-5" x2="0" y2="5" stroke="black" stroke-width=0.2
                      clip-path="url(#kiln_clip_path)" stroke-linecap="round" pointer-events="none">
                    </line>
                    <text id="selectedPositionText" x="0.2" y="0" font-size=0.7 color="888"
                      pointer-events="none"></text>
                  </g>
                </svg> 
                <div class="${styles.brickInfoIndicator}" id="brickInfoIndicator${this.#guid}"></div>
      `;

    if (this.#showContextMenu) {
      const menuItems = [
        {
          content: 'Open Panorama Image at position',
          events: {
            click: (_event) => this.openPanoramaAtPosition(this.#contextMenu.clientX, this.#contextMenu.clientY)
          }
        },
        {
          content: 'Open scan at position',
          events: {
            click: (_event) => this.openScanAtPosition(this.#contextMenu.clientX, this.#contextMenu.clientY)
          }
        },

        { content: 'Open ovality measurement' },
        // {
        //   content: `${deleteIcon}Delete`,
        //   divider: 'top' 
        // }
      ];

      this.#contextMenu = new ContextMenu({
        target: '#svgKilnBrickRegions',
        mode: 'light',
        menuItems
      });
      this.#contextMenu.init();
    }
    this.#svg = document.getElementById(`kilnSVG${this.#guid}`);
    this.#brickInfoIndicator = document.getElementById(`brickInfoIndicator${this.#guid}`);

    this.#svg.setAttribute('viewBox', '0, -4, ' + (this.#vessel.axisMax) + ', 8');
    this.setupVesselProfile();
    this.setUpEventListeners();

  }



  setupVesselProfile() {
    d3.select(this.#svg).select('#svgKilnBrickRegions')
      .selectAll('rect')
      .data(this.#vessel.brickTypes)
      .enter()
      .append('rect')
      .attr('x', dta => dta.start)
      .attr('y', -5)
      .attr('width', dta => (dta.end - dta.start))
      .attr('height', 10)
      .attr('fill', (dta,i) => dta.color||Utils.selectColor(i))
      .attr('class', styles.svgBrickRegion)
      .attr('id', (_dte, i) => 'svg-brick-region-' + i)
      .attr('brick_type_id', (_dte, i) => i)
      .attr('clip-path', 'url(#kiln_clip_path)')
      .attr('pointer-events', 'none')
      .on('click', (event) => this.kilnSVGClick(event));


    d3.select(this.#svg).select('#svgKilnComponents')
      .selectAll('rect')
      .data(this.#vessel.tyres)
      .enter()
      .append('use')
      .attr('x', dta => dta.start)
      .attr('y', 0)
      .attr('xlink:href', '#svgTyre')
      .attr('pointer-events', 'none');

    d3.select(this.#svg).select('#svgKilnComponents')
      .selectAll('rect')
      .data(this.#vessel.kilnDrives)
      .enter()
      .append('use')
      .attr('x', dta => dta.start)
      .attr('y', 0)
      .attr('xlink:href', '#svgKilndrive')
      .attr('pointer-events', 'none');

    d3.select(this.#svg).select('#svgKilnComponents')
      .selectAll('rect')
      .data(this.#vessel.panoramas)
      .enter()
      .append('use')
      .attr('x', dta => dta.fields.position)
      .attr('y', 0)
      .attr('xlink:href', '#svgPanoramaIcon')
      .attr('pointer-events', 'none');

    let prevPos = [0, 0];
    let pathString = 'm 0 0 ';


    for (let pos of this.#vessel.kilnProfile) {
      let x = (pos[0] - prevPos[0]);
      let y = -(pos[1] - prevPos[1]);

      pathString += ` l${x}  ${y}`;
      prevPos = pos;
    }

    //start heading back by moving down 2x the current vertical position
    pathString += `l 0 ${2 * prevPos[1]}`;
    for (let i = this.#vessel.kilnProfile.length - 1; i >= 0; i--) {
      let pos = this.#vessel.kilnProfile[i];
      let x = (pos[0] - prevPos[0]);
      let y = (pos[1] - prevPos[1]);
      pathString += ` l${x}  ${y}`;
      prevPos = pos;
    }
    pathString += ' z ';



    d3.select(this.#svg).select('#kilnOutline')
      .append('path')
      .attr('d', pathString)
      .attr('style', 'fill:silver;stroke:#000000;stroke-width:0.05;')
      .attr('id', 'generated_kiln_outline')
      .on('mousemove', (event) => this.kilnSVGMouseMove(event))
      .on('mouseout', (event) => this.kilnSVGMouseOut(event));


    this.#kilnSvgPt = this.#svg.createSVGPoint();
  }


  kilnSVGMouseMove(event) {
    let loc = Utils.cursorPoint(event.clientX, event.clientY, this.#kilnSvgPt, this.#svg);
    this.showPositionIndicator(loc);
    this.#vessel.setHoveredPosition(new Utils.Location(loc.x));

    if (this.#footer) {
      this.#footer.innerHTML = `Current: ${loc.x.toFixed(1)} m`;
    }

  }


  kilnSVGMouseOut(_event) {
    this.#vessel.setHoveredPosition(null);

    this.#brickInfoIndicator.classList.remove(styles.show);
  }


  kilnSVGClick(event) {
    let loc = Utils.cursorPoint(event.clientX, event.clientY, this.#kilnSvgPt, this.#svg);
    this.#vessel.setSelectedPosition(new Utils.Location(loc.x));
  }


  onSelectedKilnPositionChange(event) {

    let selectedPosition = event.detail.new_position.x;

    d3.select(this.#svg).select('#selectedPositionMarker')
      .transition()
      .duration(500)
      .attr('transform', `translate(${(selectedPosition).toFixed(2)} 0)`);

    d3.select(this.#svg).select('#selectedPositionText').text(`${(selectedPosition).toFixed(2)} m`);


    let id = this.vessel.getBrickIdAtPosition(selectedPosition);
    let previousRegion = null;

    if (id != previousRegion) {
      if (previousRegion >= 0) {
        $('#brickRegionInfoElem' + previousRegion).hide();
      }

      $('#brickRegionInfoElem' + id).fadeIn('normal', function () {
        // Animation complete
      });

      previousRegion = id;
    }


    this.#positionMarkerTween = new TWEEN.Tween(this.#positionMarkerTweenValue)
      .to({ x: selectedPosition }, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();


  }


  showPositionIndicator(loc) {
    let posInfoStr = '';
    let brick = this.#vessel.getBrickRegionAtPosition(loc);
    if (brick) {
      posInfoStr += `<p class="${styles.hoverBrickInfoElem}">${brick.attributes.Brand}</p>
      <p class="${styles.hoverBrickInfoElem}">${brick.attributes.Comment}</p>
      <p class="${styles.hoverBrickInfoElem}"> Installed: ${brick.attributes.Installed} </p>`;

      try {
        let installedDate = new Date(brick.attributes.Installed);
        if (!isNaN(installedDate)) {
          let currentDate = new Date();
          let months = (currentDate.getFullYear() - installedDate.getFullYear()) * 12;
          months -= installedDate.getMonth();
          months += currentDate.getMonth();
          months = months <= 0 ? 0 : months;
          posInfoStr += `<p class="${styles.hoverBrickInfoElem}"> Age: ${months} months </p>`;
        }
      }
      catch (e) {

      }
    }

    if (this.#showVesselDiameter) {

      posInfoStr += `<p class="${styles.hoverBrickInfoElem}"> Diameter: ${ (this.#vessel.getKilnRadiusAtPosition(loc.x)*2000).toFixed(0)} mm </p>`;


    }

    this.#brickInfoIndicator.style.top = `${event.clientY}px`;
    if (window.innerWidth / 2 > event.clientX) {
      this.#brickInfoIndicator.style.left = `${event.clientX + 10}px`;
    }
    else {
      this.#brickInfoIndicator.style.left = `${event.clientX - this.#brickInfoIndicator.clientWidth - 10}px`;
    }
    this.#brickInfoIndicator.classList.add(styles.show);
    this.#brickInfoIndicator.innerHTML = posInfoStr;

  }


  onAcceptableThicknessChange(_event) {
  }

  openPanoramaAtPosition(x, y) {
    let loc = Utils.cursorPoint(x, y, this.#kilnSvgPt, this.#svg);
    Utils.openInNewTab(`/panoramaimage-scan-view/${this.#vessel.currentScan}?meter=${loc.x}`);
  }



  openScanAtPosition(x, y) {

    let loc = Utils.cursorPoint(x, y, this.#kilnSvgPt, this.#svg);
    Utils.openInNewTab(`/scanview/${this.#vessel.currentScan}/?meter=${loc.x}`);


  }

  setUpEventListeners() {
    this.#vessel.addEventListener('LES_SELECTED_KILN_LOCATION_CHANGE', this.onSelectedKilnPositionChange);
    this.#vessel.addEventListener('LES_ACCEPTABLE_THICKNESS_CHANGE', this.onAcceptableThicknessChange);


    // this.#vessel.addEventListener('resize', this.onResize);


    // this.#target.addEventListener('click', this.onClick);
    // this.#target.addEventListener('mosemove', this.onMouseMove);
    // this.#target.addEventListener('mouseout', this.onMouseOut);
    //this.#target.addEventListener('mousemove', (event) => this.onMouseMove(event));
    //this.#target.addEventListener('mouseout', (event) => this.onMouseOut(event));
  }


}
