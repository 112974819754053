import * as styles from '../css/.module/context_menu.css';

export default class ContextMenu {
  #target;
  #menuItems;
  #mode;
  #targetNode;
  #menuItemsNode;
  #isOpened;
  #positionX;
  #positionY;
  #clientX;
  #clientY;


  constructor({ target = null, menuItems = [], mode = 'dark' }) {
    this.#target = target;
    this.#menuItems = menuItems;
    this.#mode = mode;
    this.#targetNode = this.getTargetNode();
    this.#menuItemsNode = this.getMenuItemsNode();
    this.#isOpened = false;
  }
  get positionX() {
    return this.#positionX;
  }
  get positionY() {
    return this.#positionY;
  }
  get clientX() {
    return this.#clientX;
  }
  get clientY() {
    return this.#clientY;
  }




  getTargetNode() {
    if (typeof this.#target === 'object') {
      return [this.#target];

    }

    const nodes = document.querySelectorAll(this.#target);

    if (nodes && nodes.length !== 0) {
      return nodes;
    } else {
      console.error(`getTargetNode :: "${this.#target}" target not found`);
      return [];
    }
  }

  getMenuItemsNode() {
    const nodes = [];

    if (!this.#menuItems) {
      console.error('getMenuItemsNode :: Please enter menu items');
      return [];
    }

    this.#menuItems.forEach((data, index) => {
      const item = this.createItemMarkup(data);
      item.firstChild.setAttribute(
        'style',
        `animation-delay: ${index * 0.08}s`
      );
      nodes.push(item);
    });

    return nodes;
  }

  createItemMarkup(data) {
    const button = document.createElement('BUTTON');
    const item = document.createElement('LI');

    button.innerHTML = data.content;
    button.classList.add(styles.contextMenuButton);
    item.classList.add(styles.contextMenuItem);

    if (data.divider) item.setAttribute('data-divider', data.divider);
    item.appendChild(button);

    if (data.events && data.events.length !== 0) {
      Object.entries(data.events).forEach((event) => {
        const [key, value] = event;
        button.addEventListener(key, value);
      });
    }

    return item;
  }

  renderMenu() {
    const menuContainer = document.createElement('UL');

    menuContainer.classList.add(styles.contextMenu);
    menuContainer.setAttribute('data-theme', this.#mode);

    this.#menuItemsNode.forEach((item) => menuContainer.appendChild(item));

    return menuContainer;
  }

  closeMenu(menu) {
    if (this.#isOpened) {
      this.#isOpened = false;
      menu.remove();
    }
  }


  //TODO: What is the point of not doing this directly in the constructor
  init() {
    const contextMenu = this.renderMenu();
    document.addEventListener('click', () => this.closeMenu(contextMenu));
    window.addEventListener('blur', () => this.closeMenu(contextMenu));
    document.addEventListener('contextmenu', (e) => {
      this.#targetNode.forEach((target) => {
        if (!target.contains(e.target)) {
          contextMenu.remove();
        }
      });
    });

    this.#targetNode.forEach((target) => {
      target.addEventListener('contextmenu', (e) => {
        e.preventDefault();
        this.#isOpened = true;

        const { clientX, clientY } = e;

        this.#clientX = clientX;
        this.#clientY = clientY;


        document.body.appendChild(contextMenu);

        this.#positionY =
          clientY + contextMenu.scrollHeight >= window.innerHeight
            ? window.innerHeight - contextMenu.scrollHeight - 20
            : clientY;
        this.#positionX =
          clientX + contextMenu.scrollWidth >= window.innerWidth
            ? window.innerWidth - contextMenu.scrollWidth - 20
            : clientX;

        contextMenu.setAttribute(
          'style',
          `--width: ${contextMenu.scrollWidth}px;
          --height: ${contextMenu.scrollHeight}px;
          --top: ${this.#positionY}px;
          --left: ${this.#positionX}px;`
        );
      });
    });
  }
}
