//import * as Potree from 'potree-core'

import * as Utils from './utils.js';
import * as Constants from './constants.js';


export default class PotreeViewer {

  #target;
  #guid;
  #vessel;
  #url;
	  

  //import * as THREE from '/static/potree/libs/three.js/build/three.module.js';
  //import { OrbitControls } from '/static/three/examples/jsm/controls/OrbitControls.js';
  //import { VRButton } from '/static/three/examples/jsm/webxr/VRButton.js';
  //import { XRControllerModelFactory } from '/static/three/examples/jsm/webxr/XRControllerModelFactory.js';




  constructor(target, vessel, url, {}) {
    this.#target = target;
    this.#vessel = vessel;
    this.#url = url;
    this.#guid = Utils.guid();
		
    /*
			window.viewer = new Potree.Viewer(target);
			viewer.setEDLEnabled(true);
			viewer.setFOV(60);
			viewer.setPointBudget(2_000_000);



			
			this.init()


			viewer.loadGUI(() => {
				viewer.setLanguage('en');



					// Load and add point cloud to scene
				Potree.loadPointCloud(potreeFolder + '/metadata.json', "RHI Magnesita", e => {
						
					const scene = viewer.scene;
					const pointcloud = e.pointcloud;
			
					const material = pointcloud.material;
					material.size = 1;
					material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
					material.shape = Potree.PointShape.SQUARE;
			
					scene.addPointCloud(pointcloud);
					viewer.fitToScreen();
			
					let pos = 0;
		
					setTimeout(() => {
						scene.view.position.set(pos, 0, 0);
						scene.view.lookAt(new THREE.Vector3(pos + 1, 0, 0 ));
					}, 1000);
					});
			});

	 */
  		}
		

  init() {
    return;

    document.body.appendChild(VRButton.createButton(renderer));

    // controllers

    controller1 = renderer.xr.getController(0);
    controller1.addEventListener('selectstart', onSelectStart);
    controller1.addEventListener('selectend', onSelectEnd);
    scene.add(controller1);

    controller2 = renderer.xr.getController(1);
    controller2.addEventListener('selectstart', onSelectStart);
    controller2.addEventListener('selectend', onSelectEnd);
    scene.add(controller2);

    const controllerModelFactory = new XRControllerModelFactory();

    controllerGrip1 = renderer.xr.getControllerGrip(0);
    controllerGrip1.add(controllerModelFactory.createControllerModel(controllerGrip1));
    scene.add(controllerGrip1);

    controllerGrip2 = renderer.xr.getControllerGrip(1);
    controllerGrip2.add(controllerModelFactory.createControllerModel(controllerGrip2));
    scene.add(controllerGrip2);

  }



}