'use strict';

import * as styles from '../css/.module/thickness_range.css';

import * as Utils from './utils.js';
import * as Constants from './constants.js';


export default class ThicknessRange {
  #max = 200;
  #min = 0;
  #initial = 120;
  #target;
  #guid;
  #heatmap;
  #range;
  #label;


  constructor(target, heatmap, min, max, initial) {
    this.#target = target;
    this.#heatmap = heatmap;
    this.#max = max;
    this.#min = min;
    this.#initial = initial;
    this.#guid = Utils.guid();

    this.#heatmap.vessel.addEventListener(Constants.LES_ACCEPTABLE_THICKNESS_CHANGE, (event) => this.onAcceptableThicknessChange(event));


    //the classes from bootstrap should be fine but we might want to change this 
    this.#target.innerHTML = `
        <input type="range" id="acceptableThicknessRange${this.#guid}" name="acceptableThicknessRange${this.#guid}"
          class="${styles.acceptableThicknessRange}" min="${this.#min}" max="${this.#max}" value="${this.#initial}" step="1">
        <label id="acceptableThicknessRangeLabel${this.#guid}" for="acceptableThicknessRange${this.#guid}">Acceptable thickness: ${this.#initial} mm</label>`;

    this.setupEventListeners();

    this.onValueChange();

  }


  onValueChange() {
    this.#heatmap.vessel.setAcceptableThickness(this.#range.value);
  }

  onAcceptableThicknessChange(event) {
    this.#label.innerHTML = `Acceptable thickness: ${event.detail.new_thickness} mm`;


  }
  setupEventListeners() {
    this.#range = document.getElementById('acceptableThicknessRange' + this.#guid);
    this.#range.addEventListener('input', (event) => this.onValueChange(event));
    this.#range.addEventListener('change', (event) => this.onValueChange(event));
    this.#label = document.getElementById('acceptableThicknessRangeLabel' + this.#guid);
  }
}



