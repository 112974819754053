import * as styles from '../css/.module/thickness_graph.css';


import * as Constants from './constants.js';
import * as Utils from './utils.js';
import ContextMenu from './context_menu.js';


import Chart from 'chart.js/auto';

import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);

import { getRelativePosition } from 'chart.js/helpers';

import $ from 'jquery';

export default class AxisAggregatesGraph {
  #target;
  #guid;
  #vessel;
  #data;
  #aggregateData;
  #comparisonAggregateData = null;
  #comparisonMultiplier = 1;
  #drillingMeasurementsStr = null;
  #footer = null;
  #canvas;
  #chart;
  #datasets;
  #title;
  #labelExtractor
  #contextMenu;

  constructor(target, vessel, data, {datasets = [], title = "", labelExtractor = null, footer = null, contextMenuItems= null}) {
    this.#target = target;
    this.#vessel = vessel;
    this.#data = data;
    this.#guid = Utils.guid();
    this.#title = title;
    this.#labelExtractor = labelExtractor
    this.#footer = footer;

    this.#datasets = datasets
    target.innerHTML =
      `<canvas id="axisAggregateGraphCanvas${this.#guid}" data-url=""></canvas> `;
    this.#canvas = document.getElementById(`axisAggregateGraphCanvas${this.#guid}`);
    this.setUpData();


    if (contextMenuItems) {
      this.#contextMenu = new ContextMenu({
        target: `#axisAggregateGraphCanvas${this.#guid}`,
        mode: 'light',
        menuItems
      });
    }




  }

  setUpEventListeners() {
    this.#vessel.addEventListener(Constants.LES_SELECTED_KILN_LOCATION_CHANGE, (event) => this.onSelectedKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_HOVERED_KILN_LOCATION_CHANGE, (event) => this.onHoveredKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_ACCEPTABLE_THICKNESS_CHANGE, (event) => this.onAcceptableThicknessChange(event));
  }



  setUpData() {


    //data can be provided as an objet, a function or a url
    if (this.#data === null) {
      this.setupChart();
    }
    else if (typeof this.#data === 'function') {
      const dataContainer = this.#data();
      this.#aggregateData = dataContainer.aggregates;
      this.#drillingMeasurementsStr = dataContainer.drillingMeasurements;
      this.setupChart();
    }
    else if (typeof this.#data === 'object') {
      this.#aggregateData = this.#data.aggregates;
      this.#drillingMeasurementsStr = this.#data.drillingMeasurements;
      this.setupChart();
    }
    else if (typeof this.#data === 'string') {
      this.requestData(this.#data);
    }
  }


  requestData(url) {
    /*Fetch the manifest to get access to what is available in the extracted data*/
    fetch(url)
      .then(response => response.json())
      .then(aggregates => {this.#aggregateData = aggregates; 
                           this.setupChart()});



  }



  setupChart() {
    let chartAnnotations = [{
      drawTime: 'afterDatasetsDraw',
      id: 'chartSelectedPosition',
      type: 'line',
      mode: 'vertical',
      scaleID: 'x',
      value: this.#vessel.selectedPosition.x,
      borderColor: 'black',
      borderWidth: 2,
      label: {
        backgroundColor: '#888',
        content: '',
        enabled: true
      },
    }];

    for (let brickType of this.#vessel.brickTypes) {
      chartAnnotations.push({
        drawTime: 'beforeDatasetsDraw',
        type: 'box',
        xScaleID: 'x',
        xMin: brickType.start,
        xMax: brickType.end,
        backgroundColor: brickType.color,
        borderColor: brickType.color,
        borderWidth: 1,
      });


    }

    let drillingMeasurements = {};

    if (this.#drillingMeasurementsStr) {
      let drillingMeasurementsRaw = this.#drillingMeasurementsStr.split('\n'); /*1st separator*/
      let i = drillingMeasurementsRaw.length;
      while (i--) {
        if (drillingMeasurementsRaw[i] !== '')
          drillingMeasurementsRaw[i] = drillingMeasurementsRaw[i].split(';'); /*2nd separator*/
        else
          drillingMeasurementsRaw.splice(i, 1);
      }

      for (let row of drillingMeasurementsRaw) {
        let values = { x: parseFloat(row[0]), y: parseFloat(row[1]) };
        let angle = row[2].trim();
        if (drillingMeasurements[angle] == undefined)
          drillingMeasurements[angle] = [values];
        else
          drillingMeasurements[angle].push(values);

      }
    }


    let chartDatasets = []
    for (let dataset of this.#datasets) {

      let chartDatasetTemplate =
      {
        label: 'Unknown',
        lineTension: 0,
        borderColor: '#003262',
        backgroundColor: '#003262',
        type: 'line',
        
        radius: 2,
      }


      const chartDataset = {...chartDatasetTemplate, ...dataset }
      if (this.#aggregateData) {
        chartDataset.data = chartDataset.dataExtractor(this.#aggregateData)
      }
      chartDatasets.push(chartDataset)

    }

    let ringSizeIncrementation = 0;
    for (let angle in drillingMeasurements) {

      chartDatasets.push({
        label: 'Drilling measurement: ' + angle,
        data: drillingMeasurements[angle],
        type: 'scatter',
        borderColor: Utils.selectColor(ringSizeIncrementation),
        backgroundColor: 'transparent',
        radius: 4 + ringSizeIncrementation, //so we are able to se overlapping
      });
      ringSizeIncrementation++;


    }


    const dataLabels = (this.#aggregateData) ? this.#labelExtractor(this.#aggregateData): null;
    let $chart = this.#canvas;
    let ctx = $chart.getContext('2d');
    this.#chart = new Chart(ctx, {
      type: 'scatter',
      data: {
        labels: dataLabels,
        datasets: chartDatasets
      },
      options: {
        events: ['click'],
        onClick: (e) => {
          const canvasPosition = getRelativePosition(e, this.#chart);

          // Substitute the appropriate scale IDs
          const dataX = this.#chart.scales.x.getValueForPixel(canvasPosition.x);
          const dataY = this.#chart.scales.y.getValueForPixel(canvasPosition.y);

          let location = new Utils.Location(dataX);
          this.#vessel.setSelectedPosition(location);
        },

        responsive: true,
        bezierCurve: false,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: this.#title
        },
        scales: {
          x: {
            display: true,
            min: 0,
            ticks: {
              stepSize: 1,
            },
          },
          y: {
            display: true,
            ticks: {
              stepSize: 10,
            },
          }
        },
        plugins: {
          autocolors: false,
          annotation: {
            annotations: chartAnnotations,
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem, data) {
                return Number(tooltipItem.raw).toFixed();
              }
            }
          },
        }/*,
            plugins: {zoom: {
							pan: {
								enabled: true,
								mode: 'xy' 
							},
							zoom: {
								enabled: true,
								mode: 'x',
								sensitivity: 3
							}
						}
          }
          */
      }
    });

    /* now we can start listening for events */
    this.setUpEventListeners();


  }


  onHoveredKilnPositionChange() {
    //TODO: this should be result in a change here as well?

  }

  onAcceptableThicknessChange() {
    //TODO: this should be result in a change here as well?

  }


  onSelectedKilnPositionChange(event) {
    if (this.#chart) {
      let selectedPosition = event.detail.new_location.x;
      this.#chart.options.plugins.annotation.annotations[0].value = selectedPosition;
      this.#chart.options.plugins.annotation.annotations[0].label.content = selectedPosition.toFixed(2) + ' m';
      this.#chart.update();

    }

  }



  setAggregateData (data, labelData) {
    this.#aggregateData = data;
    this.#chart.data.labels = this.#labelExtractor(labelData)
    for (let chartDataset of this.#chart.data.datasets){
      chartDataset.data = chartDataset.dataExtractor(this.#aggregateData);
   }
   this.#chart.update();
  }

  subtractData (data, removeFromLeft = false) {
    this.#comparisonAggregateData = data;
    this.#comparisonMultiplier = (removeFromLeft) ? -1: 1;
    for (let chartDataset of this.#chart.data.datasets){

      const extractedComparisonData = this.#comparisonAggregateData.data.map((elem) => chartDataset.dataExtractor(elem))
      chartDataset.data = chartDataset.data.map((elem, idx) => {return (this.#comparisonMultiplier * (elem - extractedComparisonData[idx]));})
    }
    this.#chart.update();

  }

}
