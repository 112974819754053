'use strict';

import * as Utils from './utils.js';
import * as Constants from './constants.js';


export default class Vessel {

  #guid;
  #liningProfile;
  #regionsOfInterest;

  #selectedPosition = new Utils.Location(0, null, null, 0);
  #hoveredPosition = new Utils.Location(0, null, null, 0);
  #acceptableThickness = 120;
  #maxRadius = -1;
  #kilnProfile = [];

  #extractedDataValues;
  #panoramas;
  #brickTypes;
  #permanentLining;
  #acceptableThicknessSet;
  #tyres;
  #kilnDrives;
  #id;
  #domElement;
  #currentScan;
  #currentTime;


  constructor({kilnProfile = [], extracted_data = null, liningProfile=null ,currentScan = null, panoramas=[], regionsOfInterest = null}) {

    this.#guid = Utils.guid();
    this.#extractedDataValues = extracted_data;
    this.#currentScan = currentScan;
    this.#domElement = document.createElement(`data-vessel-${this.#guid}`);

    this.#kilnProfile = kilnProfile;
    for (let profilePos of this.#kilnProfile) {
      //could be done better
      let radius = profilePos[1];
      this.#maxRadius = (radius > this.#maxRadius) ? radius : this.#maxRadius;
    }
    this.#liningProfile = liningProfile;
    this.#regionsOfInterest = regionsOfInterest;

    // make some data easily available
    this.#panoramas = [];
    this.#brickTypes = [];
    this.#permanentLining = [];
    this.#acceptableThicknessSet = [];
    this.#tyres = [];
    this.#kilnDrives = [];
    this.#panoramas = panoramas;
    if (this.#liningProfile) {

      
      this.#brickTypes = this.#liningProfile.filter(elem => elem.infotype == 'BrickType');
      this.#permanentLining = this.#liningProfile.filter(elem => elem.infotype == 'PermanentLining');
      this.#acceptableThicknessSet = this.#liningProfile.filter(elem => elem.infotype == 'AcceptableLiningThickness');
      this.#tyres = this.#liningProfile.filter(elem => elem.infotype == 'Tyre');
      this.#kilnDrives = this.#liningProfile.filter(elem => elem.infotype == 'KilnDrive');
    }
  }


  /** Getters
 *
 */
  get maxRadius() {
    return this.#maxRadius;
  }

  get kilnProfile() {
    return this.#kilnProfile;
  }

  get vesselProfile() {
    return this.#kilnProfile;
  }
  
  get axisMax() {
    return this.#kilnProfile.at(-1)[0];
  }
  get axisMin() {
    return this.#kilnProfile.at(0)[0];
  }

  get radiusDiffMaxCap() {
    return this.#extractedDataValues.config['2d_visualization'].radius_diff_max_cap;
  }

  get radiusDiffMinCap() {

    return this.#extractedDataValues.config['2d_visualization'].radius_diff_min_cap;
  }

  get brickTypes() {
    return this.#brickTypes;
  }

  get tyres() {
    return this.#tyres;
  }

  get panoramas() {
    return this.#panoramas;
  }

  get kilnDrives() {
    return this.#kilnDrives;
  }

  get extractedDataValues() {
    return this.#extractedDataValues;

  }


  get regionsOfInterest() {
    return this.#regionsOfInterest;
  }

  get acceptableThicknessSet() {
    return this.#acceptableThicknessSet;
  }

  get currentScan() {
    return this.#currentScan;
  }

  getBrickRegionAtPosition(loc) {

    if (this.#brickTypes.length == 0) return null;


    let idx = 0;
    for (let region of this.#brickTypes) {
      if (region.start <= loc.x && region.end > loc.x) {
        return this.#brickTypes[idx];
      }
      else if (loc.x < region.start) {
        return null;
      }
      idx++;
    }
    let last = this.#brickTypes[this.#brickTypes.length - 1];
    return (last.end == loc.x) ? last : null;
  }

  getBrickIdAtPosition(loc) {
    let idx = 0;
    for (let region of this.#brickTypes) {
      if (region.start <= loc.x && region.end > loc.x) {
        return idx;
      }
      else if (loc.x < region.start) {
        return -1;
      }
      idx++;
    }

    if (this.#brickTypes.length == 0) return -1;


    let last = this.#brickTypes[this.#brickTypes.length - 1];
    return (last.end == loc.x) ? this.#brickTypes.length - 1 : -1;
  }


  getKilnRadiusAtPosition(x) {

    if (x < 0)
      return this.#kilnProfile[0][1];
    if (x > this.#kilnProfile[this.#kilnProfile.length - 1][0])
      return this.#kilnProfile[this.#kilnProfile.length - 1][1];


    let prevPos = this.#kilnProfile[0];
    let radius = 0;
    for (let pos of this.#kilnProfile) {
      if (x <= pos[0]) {

        // will occur for x == 0 (at least) but straight hops should be allowed in the profile
        if (pos[0] == prevPos[0]) {
          radius = pos[1];
        }
        else {
          //so we are in a region in which the kiln is either increasing ore decreasing in radius
          let radiusChange = pos[1] - prevPos[1];
          let fractionOfDistance = (x - prevPos[0]) / (pos[0] - prevPos[0]);
          radius = prevPos[1] + radiusChange * fractionOfDistance;
        }

        return radius;
      }
      prevPos = pos;
    }
  }

  get selectedPosition() {
    return this.#selectedPosition;
  }

  get hoveredPosition() {
    return this.#hoveredPosition;
  }

  addEventListener(type, listener, useCapture) {

    this.#domElement.addEventListener(type, listener, useCapture);

  }

  setCurrentTime(newTime) {
    const isNew = this.#currentTime != newTime;
    if (isNew) {
      const event = new CustomEvent(Constants.LES_SELECTED_TIME_CHANGED, {
        detail: {
          'vessel': this,
          'prev_time': this.#currentTime,
          'new_time': newTime
        }
      });

      Utils.debugLog('LES_SELECTED_TIME_CHANGED', this.#currentTime, newTime);
      this.#currentTime = newTime;
      this.#domElement.dispatchEvent(event);

    }
  }

  setSelectedPosition(location) {
    const isNew = this.locationIsChanged(this.#selectedPosition, location);
    if (isNew) {
      const event = new CustomEvent(Constants.LES_SELECTED_KILN_LOCATION_CHANGE, {
        detail: {
          'vessel': this,
          'prev_position': this.#selectedPosition,
          'new_location': location
        }
      });
      Utils.debugLog('LES_SELECTED_KILN_LOCATION_CHANGE', this.#selectedPosition, location);

      this.#selectedPosition = location;
      this.#domElement.dispatchEvent(event);


    }
    return isNew;
  }


  get acceptableThickness() {
    return this.#acceptableThickness;
  }


  setAcceptableThickness(thickness) {
    let isNew = (this.#acceptableThickness != thickness);
    if (isNew) {
      const event = new CustomEvent(Constants.LES_ACCEPTABLE_THICKNESS_CHANGE, {
        detail: {
          'vessel': this,
          'prev_thickness': this.#acceptableThickness,
          'new_thickness': thickness
        }
      });
      Utils.debugLog('LES_ACCEPTABLE_THICKNESS_CHANGE', this.#acceptableThickness, thickness);


      this.#acceptableThickness = thickness;

      this.#domElement.dispatchEvent(event);

    }
    return isNew;
  }

  locationIsChanged(old_loc, new_loc) {

    if ((old_loc == null) != (new_loc == null))
      return true;

    if (old_loc == null && new_loc == null) {
      return false;
    }


    return (old_loc.x != new_loc.x ||
      old_loc.angle != new_loc.angle);

  }
  setHoveredPosition(location) {

    let isNew = this.locationIsChanged(this.#hoveredPosition, location);
    if (isNew) {
      const event = new CustomEvent(Constants.LES_HOVERED_KILN_LOCATION_CHANGE, {
        detail: {
          'vessel': this,
          'prev_position': this.#hoveredPosition,
          'new_location': location
        }
      });

      this.#hoveredPosition = location;

      this.#domElement.dispatchEvent(event);
      //Utils.debugLog('LES_HOVERED_KILN_LOCATION_CHANGE', this.#hoveredPosition, pos);

    }
    return isNew;
  }



  getPermanentLiningAtPosition(location) {

    if (this.#permanentLining.length == 0) { return 0; }
    let pos = location.x;
    for (let region of this.#permanentLining) {
      if (region.start <= pos && region.end > pos) {
        return region.attributes.Thickness;
      }
      else if (pos < region.start) {
        return 0;
      }

    }
    let last = this.#permanentLining[this.#permanentLining.length - 1];
    return (last.end == pos) ? last.attributes.Thickness : 0;
  }

  getBrickThicknessAtPosition(location) {
    if (this.#brickTypes.length == 0) { return 0; }
    let pos = location.x;
    for (let region of this.#brickTypes) {
      if (region.start <= pos && region.end > pos) {
        return region.attributes.Thickness;
      }
      else if (pos < region.start) {
        return 0;
      }
    }
    let last = this.#brickTypes[this.#brickTypes.length - 1];
    return (last.end == pos) ? last.attributes.Thickness : 0;
  }



  getOriginalBrickThickness() {

    let changePositions = [];
    for (let region of this.#permanentLining) {
      changePositions.push(parseFloat(region.start));
      changePositions.push(parseFloat(region.end));
    }

    for (let region of this.#brickTypes) {
      changePositions.push(parseFloat(region.start));
      changePositions.push(parseFloat(region.end));
    }

    changePositions.sort((a, b) => a - b);

    let prevThickness = this.getPermanentLiningAtPosition(0) + this.getBrickThicknessAtPosition(0);
    let prevPos = 0;
    let contour = [{ x: prevPos, y: prevThickness / 10 }];
    for (let changePosition of changePositions) {
      if (changePosition > prevPos) {
        if (changePosition > 0) {
          let thickness = this.getPermanentLiningAtPosition(changePosition - 0.001) + this.getBrickThicknessAtPosition(changePosition - 0.001);
          contour.push({ x: changePosition, y: thickness / 10 });
        }


        let thickness = this.getPermanentLiningAtPosition(changePosition + 0.001) + this.getBrickThicknessAtPosition(changePosition + 0.001);
        contour.push({ x: changePosition, y: thickness / 10 });
        prevPos = changePosition;
      }

    }
    contour.pop(); // last element will be invalid
    return contour;
  }





}