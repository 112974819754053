'use strict';

import * as styles from '../css/.module/vessel_3d.css';



import * as Utils from './utils.js';
import * as Constants from './constants.js';

import rhiLogoImg from '../assets/rhi_magnesita_logo_grey.jpg';

import * as d3 from 'd3';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';

import * as TWEEN from '@tweenjs/tween.js';
import { dot, subtract } from 'mathjs';


export default class Vessel3D {

  #target;
  #vessel;
  #data;
  #guid;
  #raycaster = new THREE.Raycaster(); // create once
  #renderer;
  #camera;
  #scene;
  #circleTween;
  #positionRing;
  #firstClick;
  #root;
  #box;
  #footer;
  #canvas;
  #controls;
  #referencePoints;

  //tooltip related
  #latestMouseProjection = null;
  #hoveredObj = null;
  #tooltipDisplayTimeout = null;
  #tooltipEnabledObjects = [];
  #mouse = new THREE.Vector2();
  #tooltipElement;

  // positioning ring releated
  #cylinder_base_normal = [1, 0, 0];
  #cylinder_base_center = [0, 0, 0];
  #ringRadialThickness;
  #ringRadialDistance;
  #ringAxialThickness;
  #ringRadius;
  #hasPositionRing;

  constructor(target, vessel, data, { footer = null, referencePoints = {}, hasPositionRing = true, ringRadialThickness = .2,
    ringRadialDistance = .1,
    ringAxialThickness = .1,
    ringRadius = null,
  }) {
    this.#target = target;
    this.#vessel = vessel;
    this.#data = data;
    this.#guid = Utils.guid();
    this.#footer = footer;
    this.#referencePoints = referencePoints;

    this.#hasPositionRing = hasPositionRing;
    if (this.#hasPositionRing) {
      this.#cylinder_base_normal = this.#referencePoints.cylinder_base_normal || [1, 0, 0];
      this.#cylinder_base_center = this.#referencePoints.cylinder_base_center || [0, 0, 0];
      this.#ringRadialThickness = ringRadialThickness;
      this.#ringRadialDistance = ringRadialDistance;
      this.#ringAxialThickness = ringAxialThickness;
      this.#ringRadius = ringRadius;
    }


    target.innerHTML =
      `
      <canvas id="vessel3dCanvas${this.#guid}" width="1000" height="400" style="background-color: white;touch-action: none;"></canvas>
       <div id="vessel3DTooltip${this.#guid}" class="${styles.vessel3DTooltip}" style=""></div>
       `;


    this.#canvas = document.getElementById(`vessel3dCanvas${this.#guid}`);
    this.#tooltipElement = document.getElementById(`vessel3DTooltip${this.#guid}`);

    console.log(this.#tooltipElement);
    this.setupScene();

  }

  onAcceptableThicknessChange(event) {

  }


  onMouseMove(event) {



    this.testIfTooltip(event);
  }


  onMouseOut(event) {

    //do someting?
  }


  onClick(event) {
    // the following line would stop any other event handler from firing
    // (such as the mouse's TrackballControls)
    event.preventDefault();
    let mouse = new THREE.Vector2(); // create once


    let rect = event.target.getBoundingClientRect();
    let x = event.clientX - rect.left; //x position within the element.
    let y = event.clientY - rect.top; //y position within the element.


    mouse.x = (x / this.#renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = - (y / this.#renderer.domElement.clientHeight) * 2 + 1;



    this.#raycaster.setFromCamera(mouse, this.#camera);

    let intersects = this.#raycaster.intersectObjects(this.#scene.children, true);

    // if there is one (or more) intersections
    if (intersects.length > 0) {
      const intersect = intersects[0];
      const newXPos = dot(subtract(intersect.point.toArray(), this.#cylinder_base_center), this.#cylinder_base_normal);


      let loc = new Utils.Location(newXPos,
        null,
        null);
      this.#vessel.setSelectedPosition(loc);
    }


  }
  frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera) {
    const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
    const halfFovY = THREE.MathUtils.degToRad(camera.fov * .5);
    const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
    // compute a unit vector that points in the direction the camera is now
    // in the xy plane from the center of the box
    const direction = (new THREE.Vector3())
      .subVectors(camera.position, boxCenter)
      .multiply(new THREE.Vector3(1, 1, 1))
      .normalize();

    // move the camera to a position distance units way from the center
    // in whatever direction the camera was from the center already
    camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

    // pick some near and far values for the frustum that
    // will contain the box.
    camera.near = boxSize / 100;
    camera.far = boxSize * 100;

    camera.updateProjectionMatrix();

    // point the camera to look at the center of the box
    camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
  }

  setupScene() {
    const canvas = this.#canvas;

    this.#renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
    this.#renderer.shadowMap.enabled = true;
    this.#renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap


    const fov = 45;
    const aspect = 2; // the canvas default
    const near = 0.1;
    const far = 100;
    this.#camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

    this.#camera.up.set(0, 0, 1);

    this.#controls = new OrbitControls(this.#camera, canvas);
    this.#controls.enableDamping = true;
    this.#controls.screenSpacePanning = false;
    //this.#controls.minAzimuth

    this.#controls.enablePan = true;
    this.#controls.target.set(0, .5, 0);
    this.#controls.update();

    this.#scene = new THREE.Scene();
    this.#scene.background = new THREE.Color('white');

    if (this.#hasPositionRing) {


      //TODO: extract constants to configuration?
      const ringRadialThickness = .2;
      const ringRadialDistance = .1;
      const ringAxialThickness = .1;
      const innerRadius = this.#ringRadius || this.#vessel.maxRadius + ringRadialDistance;



      const outerRadius = innerRadius + ringRadialThickness;

      const arcShape = new THREE.Shape();
      arcShape.absarc(0, 0, outerRadius, 0, Math.PI * 2, false);
      const holePath = new THREE.Path();
      holePath.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
      arcShape.holes.push(holePath);

      const geometry = new THREE.ExtrudeGeometry(arcShape, {
        depth: ringAxialThickness,
        bevelEnabled: false,
        steps: 1,
        curveSegments: 60
      });
      geometry.center();

      const material = new THREE.MeshStandardMaterial({ color: 0xff0000, transparent: true, opacity: 0.5 });

      this.#positionRing = new THREE.Mesh(geometry, material);

      //the object is created with the extruded geometetry in the z (0,0,1) direction
      const v1 = new THREE.Vector3(0, 0, 1);
      const v2 = new THREE.Vector3().fromArray(this.#cylinder_base_normal);
      var quaternion = new THREE.Quaternion();
      quaternion.setFromUnitVectors(v1, v2);
      this.#positionRing.applyQuaternion(quaternion);



      this.#positionRing.castShadow = true;
      this.#positionRing.receiveShadow = false;
      this.#firstClick = true;
    }
    if (LES_DEBUG) {
      const axesHelper = new THREE.AxesHelper(5);
      this.#scene.add(axesHelper);
    }




    this.onResize();

    if (this.#data.endsWith('.ply')) {
      const loader = new PLYLoader();
      loader.load(
        this.#data,
        (geometry) => {
          geometry.computeVertexNormals();
          let material = new THREE.MeshStandardMaterial({ color: 0x003262, transparent: true, opacity: 0.7 });
          material.side = THREE.DoubleSide;
          const mesh = new THREE.Mesh(geometry, material);
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          this.#root = mesh;
          this.#scene.add(this.#root);

          this.sceneLoaded();
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
        },
        (error) => {
          console.log(error);
        }
      );
    }
    else {
      const loader = new GLTFLoader();
      loader.load(this.#data, (model) => {
        this.#root = model.scene;
        this.#root.traverse(function (child) {
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            child.material.side = THREE.DoubleSide;
          }
        });

        this.#scene.add(this.#root);
        this.sceneLoaded();
      });
    }

  }





  sceneLoaded() {
    const texture = new THREE.TextureLoader().load(rhiLogoImg);

    // compute the box that contains all the stuff
    // from root and below


    this.#box = new THREE.Box3().setFromObject(this.#root);

    if (this.#hasPositionRing) {
      if (this.#vessel.selectedPosition) {
        this.#firstClick = false;
        const newPos = this.#cylinder_base_normal.map((val, idx) => val * this.#vessel.selectedPosition.x + this.#cylinder_base_center[idx]);
        this.#positionRing.position.set(newPos[0], newPos[1], newPos[2]);
        this.#scene.add(this.#positionRing);
      }
    }

    const boxSize = this.#box.getSize(new THREE.Vector3()).length();
    const boxCenter = this.#box.getCenter(new THREE.Vector3());


    const new_camera_pos = this.#box.getCenter(new THREE.Vector3()).add(new THREE.Vector3(0, -3, 2));

    this.#camera.position.copy(new_camera_pos);

    // set the camera to frame the box
    this.frameArea(boxSize * 0.8, boxSize, boxCenter, this.#camera);

    // update the Trackball controls to handle the new size
    this.#controls.maxDistance = boxSize * 10;
    this.#controls.target.copy(boxCenter);
    this.#controls.update();



    let repeatX, repeatY;
    const width = (this.#box.max.x - this.#box.min.x);
    const depth = (this.#box.max.y - this.#box.min.y);

    const center_x = (this.#box.max.x + this.#box.min.x) / 2;
    const center_y = (this.#box.max.y + this.#box.min.y) / 2;

    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    repeatX = 1;
    repeatY = 1;
    texture.repeat.set(repeatX, repeatY);
    texture.offset.x = (repeatX - 1) / 2 * -1;
    texture.offset.y = (repeatY - 1) / 2 * -1;

    const material = new THREE.MeshStandardMaterial({ map: texture, color: 0xFFFFFF, });
    let geometry = new THREE.PlaneGeometry(width * 2, depth * 2, 1);
    let groundPlane = new THREE.Mesh(geometry, material);
    groundPlane.position.x = center_x;
    groundPlane.position.y = center_y;
    groundPlane.position.z = this.#box.min.z - .4;
    groundPlane.receiveShadow = true;
    this.#scene.add(groundPlane);


    if (this.#referencePoints) {
      const rpMaterial = new THREE.MeshStandardMaterial({ color: 0xff5500 });
      for (const [name, pos] of Object.entries(this.#referencePoints)) {

        const geometry = new THREE.SphereGeometry(.1, 15, 15);
        const sphere = new THREE.Mesh(geometry, rpMaterial);
        sphere.position.set(pos[0], pos[1], pos[2]);

        sphere.userData.tooltipText = name;
        this.#tooltipEnabledObjects.push(sphere);
        this.#scene.add(sphere);

      }
    }


    const ambientLight = new THREE.AmbientLight(0x808080, 0.7); // soft white light
    this.#scene.add(ambientLight);

    const lightPositions = [[center_x + width / 2, center_y + depth / 2, this.#box.max.z + 4], [center_x - width / 2, center_y - depth / 2, this.#box.max.z + 4]];

    for (let lightPosition of lightPositions) {
      const color = 0xf0f0f0;
      const intensity = 1;
      const light = new THREE.DirectionalLight(color, intensity, 2);
      light.position.set(lightPosition[0], lightPosition[1], lightPosition[2]);
      light.castShadow = true;
      light.shadow.mapSize.width = 512; // default
      light.shadow.mapSize.height = 512; // default
      light.shadow.camera.near = 0.1;
      light.shadow.camera.far = 500;

      this.#scene.add(light);
      this.#scene.add(light.target);
      //const helper = new THREE.CameraHelper(light.shadow.camera);
      //this.#scene.add(helper);
      if (LES_DEBUG) {
        const lightHelper = new THREE.DirectionalLightHelper(light, .5);
        this.#scene.add(lightHelper);

      }

      this.onResize();

      this.setupEventListeners();
      //model received now we can start acting on events

      this.render(1);
    }
  }
  onSelectedKilnPositionChange(event) {

    if (this.#firstClick) {
      this.#scene.add(this.#positionRing);
      this.#firstClick = false;
    }


    this.#firstClick = false;
    let newPos = this.#cylinder_base_normal.map((val, idx) => val * this.#vessel.selectedPosition.x + this.#cylinder_base_center[idx]);
    this.#circleTween = new TWEEN.Tween(this.#positionRing.position).easing(TWEEN.Easing.Elastic.Out);
    this.#circleTween.to({ 'x': newPos[0], 'y': newPos[1], 'z': newPos[2] }, 1000);
    this.#circleTween.start();



    if (this.#footer) {
      let str = `Current position: ${event.detail.new_location.x.toFixed(1)} m `;
      d3.select(this.#footer).text(str);
    }


  }

  resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    this.#renderer.setSize(width, height, false);

    this.#camera.aspect = canvas.clientWidth / canvas.clientHeight;
    this.#camera.updateProjectionMatrix();


  }


  render(timestamp) {
    requestAnimationFrame((timestamp) => this.render(timestamp));
    TWEEN.update();
    this.#controls.update();

    this.#renderer.render(this.#scene, this.#camera);

  }

  onResize(event) {

    let rect = this.#target.getBoundingClientRect();
    this.#canvas.width = rect.width;
    this.#canvas.height = rect.height;

    if (this.#renderer != null) {
      this.#renderer.setSize(this.#target.clientWidth, this.#target.clientHeight);
    }
  }
  setupEventListeners() {


    window.addEventListener('resize', (event) => this.onResize(event));
    // for the hover on reference points
    this.#target.addEventListener('mousemove', (event) => this.onMouseMove(event));

    if (this.#hasPositionRing) {
      this.#vessel.addEventListener(Constants.LES_SELECTED_KILN_LOCATION_CHANGE, (event) => this.onSelectedKilnPositionChange(event));
      this.#vessel.addEventListener(Constants.LES_ACCEPTABLE_THICKNESS_CHANGE, (event) => this.onAcceptableThicknessChange(event));
      this.#target.addEventListener('click', (event) => this.onClick(event), false);
      this.#target.addEventListener('mouseout', (event) => this.onMouseOut(event));
    }
  }







  showTooltip() {

    if (this.#tooltipElement && this.#latestMouseProjection) {
      this.#tooltipDisplayTimeout = null;

      this.#tooltipElement.style.display = 'block';
      this.#tooltipElement.style.opacity = 0.0;

      const tooltipPosition = this.#latestMouseProjection;
      const tootipWidth = this.#tooltipElement.offsetWidth;
      const tootipHeight = this.#tooltipElement.offsetHeight;

      this.#tooltipElement.style.left = `${tooltipPosition.x - tootipWidth / 2}px`;
      this.#tooltipElement.style.top = `${tooltipPosition.y - tootipHeight - 5}px`;

      this.#tooltipElement.innerHTML = this.#hoveredObj.userData.tooltipText;

      setTimeout(this.makeTooltipVisible.bind(this), 25);
    }
  }

  makeTooltipVisible() {

    this.#tooltipElement.style.opacity = 1.0;

  }

  // This will immediately hide tooltip.
  hideTooltip() {
    this.#tooltipElement.style.display = 'none';
  }




  testIfTooltip(event) {
    let rect = event.target.getBoundingClientRect();
    let x = event.clientX - rect.left; //x position within the element.
    let y = event.clientY - rect.top; //y position within the element.


    this.#mouse.x = (x / this.#renderer.domElement.clientWidth) * 2 - 1;
    this.#mouse.y = - (y / this.#renderer.domElement.clientHeight) * 2 + 1;



    this.#raycaster.setFromCamera(this.#mouse, this.#camera); {
      let intersects = this.#raycaster.intersectObjects(this.#tooltipEnabledObjects);
      if (intersects.length > 0) {
        this.#latestMouseProjection = { x: event.clientX, y: event.clientY };
        this.#hoveredObj = intersects[0].object;
      }
    }

    if (this.#tooltipDisplayTimeout || !this.#latestMouseProjection) {
      clearTimeout(this.#tooltipDisplayTimeout);
      this.#tooltipDisplayTimeout = null;
      this.hideTooltip();
    }


    if (!this.#tooltipDisplayTimeout && this.#latestMouseProjection) {
      this.#tooltipDisplayTimeout = setTimeout(this.showTooltip.bind(this), 330);



    }
  }






}