

import * as styles from '../css/.module/circular_slice.css';

import * as Utils from './utils.js';

import * as Constants from './constants.js';


import $ from 'jquery';
import * as d3 from 'd3';

import * as chroma from 'chroma-js';



export default class CircularSlice {

  #target;
  #guid;
  #vessel;
  #data;
  #svg;
  #targetPt;


  #lowerCap;
  #upperCap;
  #showThicknessColorization;
  #spinner;
  #widthPadding;
  #heightPadding;
  #sliceMin = 0;
  #sliceMax = 0;

  
  #previousRegion;

  #maxWidth;
  #maxHeight;
  #sliceThickness;
  #canvasHeight;
  #canvasWidth;
  #currentPosition = 0;
  #footer;

  #colorScale = chroma.scale('viridis');
  #angularDistComparisonTolerance = 0.06;


  #scatter = null;
  #comparisonScatter = null;
  #sliceAngleIndicator;
  #sliceAngleIndicatorText;
  #showAcceptableThickness = false;
  #showVesselProfile = true;
  #fixedAngles = null;
  #fixedPosition;
  


  constructor(target, vessel, data, {lowerCap = 0, upperCap = 220, showThicknessColorization = false, sliceThickness = 0.1, 
    fixedPosition = null, widthPadding = 0.7, heightPadding=0.2, footer = null, showAcceptableThickness = false, fixedAngles = null, showVesselProfile = true}) {
    this.#target = target;
    this.#vessel = vessel;
    this.#data = data;
    this.#guid = Utils.guid();
    this.#fixedPosition = fixedPosition;
    this.#sliceThickness = sliceThickness;
    this.#showAcceptableThickness = showAcceptableThickness;
    this.#showVesselProfile = showVesselProfile;

    this.#widthPadding = widthPadding;
    this.#heightPadding = heightPadding;

    this.#footer = footer;
    this.#lowerCap = lowerCap;
    this.#upperCap = upperCap;
    this.#showThicknessColorization = showThicknessColorization;

    this.#previousRegion = null;

    this.#fixedAngles = fixedAngles;

    this.#target.innerHTML = `<svg class="${styles.sliceContainer}" id="sliceSVG${this.#guid}" width="100%" height="100%" viewBox="-2.2, -2.2, 4.4, 4.4">
    <g id="${styles.sliceDot}"></g>
    <g id="comparisonSliceDot${this.#guid}"></g>
    <g id="${styles.innerRadius}"></g>
    <g id="${styles.acceptableRadius}"></g>
    <g id="${styles.originalThicknessRing}"></g>
    <g id="${styles.fixedAngles}"></g>

    <line class="${styles.sliceAngleIndicator}" id="sliceAngleIndicator${this.#guid}" x1="0" y1="0" x2="0" y2="0"></line>
    <text  class="${styles.sliceAngleIndicatorText}" id="sliceAngleIndicatorText${this.#guid}" x="-10" y="-10"></text >
  </svg >
  `;


    this.#svg = document.getElementById('sliceSVG' + this.#guid);
    this.#sliceAngleIndicator = document.getElementById('sliceAngleIndicator' + this.#guid);
    this.#sliceAngleIndicatorText = document.getElementById('sliceAngleIndicatorText' + this.#guid);



    // Create an SVGPoint for future math
    this.#targetPt = this.#svg.createSVGPoint();

    const rect = this.#target.getBoundingClientRect();
    this.#canvasHeight = rect.height;
    this.#canvasWidth = rect.width;




    
    this.#scatter = new LES.Scatter(this.#data, {preCalcAngle: true});
    this.#scatter.fetchHeader((event) => this.headerFetched(event));
  }


  onClick() {
    //not decided what todo... highlight somehow?

  }


  onAcceptableThicknessChange() {
    this.updateAcceptableThickness();

  }


  onHoveredKilnPositionChange(event) {
    if (event.detail.new_location != null) {

      if (event.detail.new_location.angle) {
        if (Math.abs(this.#sliceMin <= event.detail.new_location.x && this.#sliceMax >= event.detail.new_location.x))
          this.sliceHighlightAngle(event.detail.new_location.angle * Math.PI * 2 / 360);
      }
    }
  }

  setPosition(){


    if (this.#fixedPosition !== null ) {
      this.#sliceMin = this.#fixedPosition;
      this.#sliceMax = this.#fixedPosition + this.#sliceThickness;
    }
    else {
      this.#sliceMin = this.#vessel.selectedPosition.x;
      this.#sliceMax = this.#vessel.selectedPosition.x + this.#sliceThickness;
    }
    if (!this.#comparisonScatter) {
      // we only have one scatter to worry about
      this.#scatter.getLocation(this.#sliceMin, this.#sliceMax).then(() => {this.renderSlice();});
    }
    else {
      // neeed to fetch data for both datasets
      Promise.all([
        this.#scatter.getLocation(this.#sliceMin, this.#sliceMax),
        this.#comparisonScatter.getLocation(this.#sliceMin, this.#sliceMax)
      ]).then(data => this.renderSlice());
    }
  }

  


  onSelectedKilnPositionChange(_event) {
    if (!this.positionIsFixed) {
      this.setPosition();
    }

  }

  setupEventListeners() {
    this.#vessel.addEventListener(Constants.LES_HOVERED_KILN_LOCATION_CHANGE, (event) => this.onHoveredKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_SELECTED_KILN_LOCATION_CHANGE, (event) => this.onSelectedKilnPositionChange(event));
    this.#vessel.addEventListener(Constants.LES_ACCEPTABLE_THICKNESS_CHANGE, (event) => this.onAcceptableThicknessChange(event));

    window.addEventListener('resize', (event) => this.onResize(event));

    this.#svg.addEventListener('click', (event) => this.onClick(event));
    this.#svg.addEventListener('mousemove', (event) => this.onMouseMove(event));
    this.#svg.addEventListener('mouseout', (event) => this.onMouseOut(event));
  }

  getDistScale (comparison, base, invertedDistance = false){

    //these are both sorted so for quicker access
    let prevBaseFound = 0;
    let multiplier = 1; 
    if (invertedDistance) multiplier = -1;

    const scale = new Array(comparison.length);

    for (let i = 0; i <comparison.length; i ++) {
      scale[i] = -1000000;

      while(prevBaseFound < (base.length-1) && base[prevBaseFound][1] < comparison[i][1]) {
        prevBaseFound ++;
      }


      const dist = (multiplier)*(comparison[i][2] - base[prevBaseFound][2]);
      const verticalDist = comparison[i][1] - base[prevBaseFound][1];

      if (verticalDist < this.#angularDistComparisonTolerance) {
        scale[i] = LES.Utils.clampAndNormalize(dist*1000, this.#lowerCap, this.#upperCap);

      }



    }


    return scale;


  }
  headerFetched() {

    this.#maxWidth = this.#scatter.dim1Max - this.#scatter.dim1Min;
    this.#maxHeight = this.#scatter.dim0Max - this.#scatter.dim0Min;


    let minWidth = this.#scatter.dim1Min;
    let maxWidth = this.#scatter.dim1Max;
    let minHeight = this.#scatter.dim0Min;
    let maxHeight = this.#scatter.dim0Max;

    const maxRadius = this.#vessel.maxRadius;

    if (this.#vessel.maxRadius) {
      minWidth = Math.min(-maxRadius, minWidth);
      maxWidth = Math.max(maxRadius, maxWidth);
      minHeight = Math.min(-maxRadius, minHeight);
      maxHeight = Math.max(maxRadius, maxHeight);


    }


    const paddedXMax = (maxWidth + this.#widthPadding);
    const paddedYMin = (minHeight - this.#heightPadding);

    this.paddedWidth = (maxWidth - minWidth + this.#widthPadding * 2);
    this.paddedHeight = (maxHeight - minHeight + this.#heightPadding * 2);

    //negation of x_min due to us wantning to flip it as y iz in "negative direction"
    this.#svg.setAttribute('viewBox', `${-paddedXMax}, ${paddedYMin}, ${this.paddedWidth}, ${this.paddedHeight}`);

    this.setupEventListeners();
    this.setPosition();
  }

  renderFixedAngles(){

    if (this.#fixedAngles && this.#scatter.pointData) {


      const angularDistance = this.#fixedAngles.step || 10;


      const angles = [];
      for (let angle of Utils.range(-Math.PI, Math.PI-angularDistance* Math.PI * 2 / 360, angularDistance* Math.PI * 2 / 360)) {


        const closestAnglePoint = Utils.binSearch(this.#scatter.angleData, angle);
        const closestPointIdx = this.#scatter.angleData[closestAnglePoint][1];
        const outer_pos = this.#vessel.maxRadius;
        const pointDistance = Math.sqrt(this.#scatter.pointData[closestPointIdx][1]**2 + this.#scatter.pointData[closestPointIdx][2]**2);
       
        const startpos_x = -this.#scatter.pointData[closestPointIdx][2];
        const startpos_y = -this.#scatter.pointData[closestPointIdx][1];
        let endpos_x = Math.sin(this.#scatter.angleData[closestAnglePoint][0]) * outer_pos;
        let endpos_y = Math.cos(this.#scatter.angleData[closestAnglePoint][0]) * outer_pos;
        let distanceVal = ((this.#vessel.getKilnRadiusAtPosition(this.#sliceMin) - pointDistance) * 1000);

        if (this.#comparisonScatter) {
          const closestComparisonAnglePoint = Utils.binSearch(this.#comparisonScatter.angleData, angle);
          const closestComparisonPointIdx = this.#comparisonScatter.angleData[closestComparisonAnglePoint][1];
          const thickness = Math.sqrt((this.#scatter.pointData[closestPointIdx][1] - this.#comparisonScatter.pointData[closestComparisonPointIdx][1])**2 + 
                                    (this.#scatter.pointData[closestPointIdx][2] - this.#comparisonScatter.pointData[closestComparisonPointIdx][2])**2);

          //endpos_y = -this.#comparisonScatter.pointData[closestComparisonPointIdx][1];
          //endpos_x = -this.#comparisonScatter.pointData[closestComparisonPointIdx][2];
          distanceVal = (thickness * 1000);
        }

        let textpos = {x: endpos_x, y: endpos_y};

        if (startpos_x**2 +startpos_y**2 > endpos_y**2 + endpos_x**2) {
          textpos.x = startpos_x;
          textpos.y = startpos_y;
        }



        angles.push({text: {pos: textpos, value:distanceVal}, line: {start:{x:startpos_x, y: startpos_y}, end:{x:endpos_x, y:endpos_y } }});

      }



      const distMean = Utils.average(angles.map(e=>e.text.value));
      const distMin = Math.min(...angles.map(e=>e.text.value));
      const distMax = Math.max(...angles.map(e=>e.text.value));

      
      d3.select(`#sliceSVG${this.#guid} > #${styles.fixedAngles}`).selectAll('line')
        .data(angles)
        .join('line')
        .attr('x1', dta=>dta.line.start.x)
        .attr('y1', dta=>dta.line.start.y)
        .attr('x2', dta=>dta.line.end.x)
        .attr('y2', dta=>dta.line.end.y);

      d3.select(`#sliceSVG${this.#guid} > #${styles.fixedAngles}`).selectAll('text')
        .data(angles)
        .join('text')
        .attr('x', dta => dta.text.pos.x)
        .attr('y', dta => dta.text.pos.y)
        .attr('alignment-baseline', dta => (dta.text.pos.y < 0) ? 'baseline' : 'hanging')
        .attr('text-anchor', dta => (dta.text.pos.x > 0) ? 'start' : 'end')
        .attr('fill', dta => (dta.text.value > distMean) ? 'blue' : 'red')
        .attr('font-weight', dta => (dta.text.value == distMin || dta.text.value == distMax) ? 'bold' : 'normal')
        .text(dta => dta.text.value.toFixed(0));




    }

  }

  renderSlice() {
    $(this.#spinner).fadeOut(1000);


    if (this.#scatter.pointData && this.#scatter.pointData.length > 0) {


      const radii = this.#scatter.pointData.map(a => Math.sqrt(a[1]**2 + a[2]**2));


      const kilnRadius = this.#vessel.getKilnRadiusAtPosition(this.#vessel.selectedPosition.x);

      const min_radius = d3.min(radii);
      const mean_radius = d3.mean(radii);
      const median_radius = d3.median(radii);
      const q95_radius = d3.quantile(radii, 0.95);
      const max_radius = d3.max(radii);

      const max_thickness = (kilnRadius - min_radius) * 1000;
      const mean_thickness = (kilnRadius - mean_radius) * 1000;
      const median_thickness = (kilnRadius - median_radius) * 1000;
      const q05_thickness = (kilnRadius - q95_radius) * 1000;
      const min_thickness = (kilnRadius - max_radius) * 1000;


      if (this.#footer) {
        const str = `Current position: ${this.#currentPosition.toFixed(1)} m , Thickness values: Min: ${min_thickness.toFixed(0)} mm, Mean: ${mean_thickness.toFixed(0)} mm, Median: ${median_thickness.toFixed(0)} mm, Q05: ${q05_thickness.toFixed(0)} mm, Max: ${max_thickness.toFixed(0)} mm`;
        d3.select(this.#footer).text(str);
      }

      //Draw or update the the individual measurements dots
      d3.select(`#sliceSVG${this.#guid} > #${styles.sliceDot}`)
        .selectAll('circle')
        .data(this.#scatter.pointData)
        .join('circle')
        .attr('cx', dta => -dta[2])
        .attr('cy', dta => -dta[1])
        .attr('r', 0.01)
        .attr('id', (_dta, idx) => 'dot' + idx);

      if (this.#showVesselProfile) {
      //Draw or update the inner kiln radius
        const innerRadius = d3.select(`#sliceSVG${this.#guid} > #${styles.innerRadius}`)
          .selectAll('circle')
          .data([kilnRadius]);

        innerRadius.exit().remove();
        innerRadius.enter()
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', dta => dta.kiln_radius);

        innerRadius.transition()
          .duration(1000)
          .attr('r', dta => dta.kiln_radius);



        const originalThickness = this.#vessel.getBrickThicknessAtPosition(this.#vessel.selectedPosition) + this.#vessel.getPermanentLiningAtPosition(this.#vessel.selectedPosition);
        //Draw or update the original thickness circle
        const originalThicknessRing = d3.select(`#sliceSVG${this.#guid} > #${styles.originalThicknessRing}`)
          .selectAll('circle')
          .data([kilnRadius]);

        originalThicknessRing.exit().remove();
        originalThicknessRing.enter()
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', kilnRadius - originalThickness / 1000)
          .attr('id', styles.originalThicknessRing);
 

        originalThicknessRing.transition()
          .duration(1000)
          .attr('r', kilnRadius - originalThickness / 1000);


      }

      
      if (this.#comparisonScatter) {

        if (this.#showThicknessColorization) {

          console.error('Not Implemented for circular');
  
        } 
        else
        {       
          d3.select('#comparisonSliceDot' + this.#guid)
            .selectAll('circle')
            .data(this.#comparisonScatter.pointData)
            .join('circle')
            .attr('cx', dta => -dta[2]) //negative value as expected to look along the kiln and then y needs to be negated
            .attr('cy', dta => -dta[1]) //negative value as svg is bottom up;
            .attr('r', 0.01)
            .style('fill', (dta, idx) => '#FF0000');
  
        }
  
      }

      this.renderFixedAngles();

      this.updateAcceptableThickness();
    }

    else {


      if (this.#footer) {
        const str = `Current position: ${this.#currentPosition.toFixed(1)} m , No values for this position`;
        d3.select(this.#footer).text(str);
      }
      d3.select(`#sliceSVG${this.#guid} > #${styles.sliceDot}`).selectAll('circle').remove();

      const innerRadius = d3.select(`#sliceSVG${this.#guid} > #${styles.innerRadius}`)
        .selectAll('circle')
        .data([0]);


      innerRadius.transition()
        .duration(1000)
        .attr('r', 0);



      const originalThicknessRing = d3.select(`#sliceSVG${this.#guid} > #${styles.originalThicknessRing}`)
        .selectAll('circle')
        .data([0]);

      originalThicknessRing.transition()
        .duration(1000)
        .attr('r', 0);


    }






  }

  comparsionHeaderFetched(comparisonScatter) {

    this.#comparisonScatter = comparisonScatter;
    this.setPosition();

  }
  compareWith(path) {
    this.#comparisonScatter = null;
    const comparisonScatter = new LES.Scatter(path, {preCalcAngle: true});
    comparisonScatter.fetchHeader(scatter => { this.comparsionHeaderFetched(scatter); });
  }


  updateAcceptableThickness() {

    const kilnRadius = this.#vessel.getKilnRadiusAtPosition(this.#vessel.selectedPosition.x);


    if (this.#showAcceptableThickness) {

      const acceptableRadius = d3.select(`#sliceSVG${this.#guid} > #acceptableRadius`)
        .selectAll('circle')
        .data([acceptableThickness]);

      acceptableRadius.exit().remove();

      acceptableRadius.enter()
        .append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', dta => kilnRadius - (dta / 1000))
        .attr('stroke', 'red')
        .attr('stroke-width', 0.02)
        .attr('fill', 'none');

      acceptableRadius.transition()
        .duration(1000)
        .attr('r', dta => kilnRadius - (dta / 1000));
    }
  }


  onResize() {
    const rect = this.#target.getBoundingClientRect();
    this.#canvasHeight = rect.height;
    this.#canvasWidth = rect.width;

  }


  sliceHighlightAngle(angle) {
    if (this.#scatter.pointData) {

      const closestAnglePoint = Utils.binSearch(this.#scatter.angleData, angle);
      const closestPointIdx = this.#scatter.angleData[closestAnglePoint][1];
      const outer_pos = this.#vessel.maxRadius;
      const pointDistance = Math.sqrt(this.#scatter.pointData[closestPointIdx][1]**2 + this.#scatter.pointData[closestPointIdx][2]**2);
       
      const startpos_x = -this.#scatter.pointData[closestPointIdx][2];
      const startpos_y = -this.#scatter.pointData[closestPointIdx][1];
      let endpos_x = Math.sin(this.#scatter.angleData[closestAnglePoint][0]) * outer_pos;
      let endpos_y = Math.cos(this.#scatter.angleData[closestAnglePoint][0]) * outer_pos;
      let distanceVal = ((this.#vessel.getKilnRadiusAtPosition(this.#sliceMin) - pointDistance) * 1000).toFixed(0);

      if (this.#comparisonScatter) {
        const closestComparisonAnglePoint = Utils.binSearch(this.#comparisonScatter.angleData, angle);
        const closestComparisonPointIdx = this.#comparisonScatter.angleData[closestComparisonAnglePoint][1];
        const thickness = Math.sqrt((this.#scatter.pointData[closestPointIdx][1] - this.#comparisonScatter.pointData[closestComparisonPointIdx][1])**2 + 
                                    (this.#scatter.pointData[closestPointIdx][2] - this.#comparisonScatter.pointData[closestComparisonPointIdx][2])**2);

        endpos_y = -this.#comparisonScatter.pointData[closestComparisonPointIdx][1];
        endpos_x = -this.#comparisonScatter.pointData[closestComparisonPointIdx][2];
        distanceVal = (thickness * 1000).toFixed(0);
      }

      let textpos = {x: endpos_x, y: endpos_y};

      if (startpos_x**2 +startpos_y**2 > endpos_y**2 + endpos_x**2) {
        textpos.x = startpos_x;
        textpos.y = startpos_y;
      }



      d3.select(this.#sliceAngleIndicator)
        .attr('x1', -this.#scatter.pointData[closestPointIdx][2])
        .attr('y1', -this.#scatter.pointData[closestPointIdx][1])
        .attr('x2', endpos_x)
        .attr('y2', endpos_y);

      d3.select(this.#sliceAngleIndicatorText)
        .attr('x', textpos.x)
        .attr('y', textpos.y)
        .attr('text-anchor', (endpos_x > 0) ? 'start' : 'end')
        .text(distanceVal + ' mm');
    }


  }
  onMouseMove(event) {


    const cursor = Utils.cursorPoint(event.clientX, event.clientY, this.#targetPt, this.#svg);
    let pointerAngle = Math.atan2(-cursor.y, cursor.x);
    //for points the angle is 0 att floor
    pointerAngle += Math.PI / 2;
    if (pointerAngle > Math.PI) pointerAngle -= 2 * Math.PI;

    this.sliceHighlightAngle(pointerAngle);
  }

  onMouseOut(_event) {

  }


}